import SectionWrapper from 'components/SectionWrapper/SectionWrapper';
import React, { createRef, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { I18n } from 'core';
import { navigate } from 'navigation';
import { LoadingButton } from 'components';
import Button from 'components/Button/Button';
import customToast from 'components/CustomToast/CustomToast';
import { toast } from 'react-toastify';

import FullPageSyncLoader from 'components/FullPageSyncLoader';
import { useFetchCommunity } from 'hooks';
import CommunitiesServiceIntance, { CommunitiesService } from 'services/remote/communities-service';
import { CommunityAPII, CommunityModel } from 'types/models/community';
import styles from './comunidades-custom-onboarding-process-page.module.scss';

import { ComunidadesCustomOnboardingInformeAutomatico } from './components/comunidades-custom-onboarding-informe-automatico';

export type SaveDataHandle = {
  getData: () => void;
};

export const ComunidadesCustomOnboardingProcessPageInformeAutomatico = (): JSX.Element => {
  const { communityID } = useParams<{ communityID: string }>();

  const [communityCustomOnboarding, setCommunityCustomOnboarding] = useState<CommunityModel | undefined>(undefined);

  const customOnboardingRef = createRef<SaveDataHandle>();

  const [loading, setLoading] = useState<boolean>(false);

  const getCustomOnboardingData = async (id: string): Promise<void> => {
    setLoading(true);
    try {
      const customOnboarding = await new CommunitiesService().getCommunity(id);
      if (customOnboarding) {
        setCommunityCustomOnboarding(customOnboarding);
      }
    } catch (error) {
      toast.error(I18n.t('ComunidadesCustomOnboardingProcess.errors.errorGettingCustomOnboardingData'));
    } finally {
      setLoading(false);
    }
  };

  const updateCustomOnboarding = async (communityCustomOnboardingUpdate: CommunityAPII): Promise<void> => {
    try {
      await CommunitiesServiceIntance.putCommunities(communityCustomOnboardingUpdate);

      customToast.success(I18n.t('ComunidadesCustomOnboardingProcess.updateSuccess'));
    } catch (error) {
      toast.error(I18n.t('ComunidadesCustomOnboardingProcess.errors.updating'));
    }
  };

  useEffect(() => {
    if (!communityID) return;
    getCustomOnboardingData(communityID);
  }, []);
  const fetchCommunityData = useFetchCommunity(communityID);
  useEffect(() => {
    fetchCommunityData();
  }, [fetchCommunityData]);

  if (loading) return <FullPageSyncLoader icon />;

  return (
    <div className={styles.container}>
      <SectionWrapper customClassname={styles.containerConfirmacionMensaje}>
        <>
          {communityCustomOnboarding && (
            <ComunidadesCustomOnboardingInformeAutomatico
              ref={customOnboardingRef}
              communityId={communityID}
              communityCustomOnboarding={communityCustomOnboarding}
              saveDetails={(customOnboarding) => updateCustomOnboarding(customOnboarding)}
            />
          )}
        </>

        <div className={styles.positionBotonoes}>
          <div className={styles.divBoton}>
            <Button
              type="button"
              onClick={(): Promise<void> => navigate('/tecnic/communities')}
              variant="secondary"
              text={I18n.t('Cancel')}
            />
            <LoadingButton type="submit" text={I18n.t('Save')} onClick={() => customOnboardingRef.current?.getData()} />
          </div>
        </div>
      </SectionWrapper>
    </div>
  );
};
