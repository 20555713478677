/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useRef } from 'react';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/ArrowDown.svg';
import cx from 'classnames';
import { ReactComponent as TickIcon } from 'assets/icons/Tick_Thin.svg';
import { useOutsideClick } from 'hooks';
import { InscritosModel } from 'types/models/inscritos';
import styles from './ElecsumSelect.module.scss';

export interface SelectItemI {
  id: string;
  value: string | null;
  label: string;
}

interface ElecsumInscritoSelectPropsI {
  selected?: InscritosModel;
  values: Array<InscritosModel>;
  label: string;
  minWidth?: '7.5rem' | '10rem' | '12.5rem' | '15rem' | '17.5rem' | '20rem';
  onChange(element: InscritosModel): void;
}

const ElecsumInscritoSelect = (props: ElecsumInscritoSelectPropsI) => {
  const dropdownRef = useRef(null);
  const { selected, values, label, onChange, minWidth } = props;

  const [open, setOpen] = useState(false);

  useOutsideClick(dropdownRef, () => {
    setOpen(false);
  });

  const getHeadLabel = (): string => {
    if (!selected) return `${label}`;
    if (selected.id === null) return `${label}`;
    return `${selected.nombre}`;
  };

  return (
    <div style={{ minWidth }} ref={dropdownRef} className={styles.container}>
      <button onClick={() => setOpen(!open)} className={cx(styles.head, { [styles.open]: open })} type="button">
        <span className={styles.label}>{getHeadLabel()}</span>
        <ArrowDownIcon />
      </button>

      <ul className={cx(styles.itemsComunidad, { [styles.open]: open })}>
        {values.map((item) => (
          <li
            onClick={() => {
              setOpen(false);
              onChange(item);
            }}
            className={cx(styles.item, { [styles['item--selected']]: item.id === selected?.id })}
          >
            <span>{item.nombre}</span>
            {item.id === selected?.id ? <TickIcon /> : null}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ElecsumInscritoSelect;
