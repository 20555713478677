import ImageType from 'types/enums/ImageTypes';
import { Serializable } from 'types/interfaces';
import { TranslatableJSON } from 'types/interfaces/polyglot';

export enum Customizable {
  SI = 'si',
  NO = 'no',
}
export const VALORDEFECTOES = 'VALOR EN ESP';
export const VALORDEFECTOCA = 'VALOR EN CA';

export const CUSTOMIZABLE_VALUES = Object.values(Customizable);

export type CustomizableType = typeof CUSTOMIZABLE_VALUES[number];

export enum CustomizableImageType {
  LOGO = 'logo',
  IMAGE_STEP_01 = 'imagen-paso-01',
  IMAGE_STEP_02 = 'imagen-paso-02',
  IMAGE_STEP_03 = 'imagen-paso-03',
  IMAGE_STEP_04 = 'imagen-paso-04',
  IMAGE_STEP_05 = 'imagen-paso-05',
}

export const CUSTOMIZABLE_IMAGE_TYPE_VALUES = Object.values(CustomizableImageType);

export type CustomizableImageTypeType = typeof CUSTOMIZABLE_IMAGE_TYPE_VALUES[number];

export interface CustomizableImage {
  id: string;
  tipo: CustomizableImageType;
  url: string;
  mostrar: ImageType;
}
export interface FaqsApi {
  id: number;
  pregunta: string;
  respuesta: string;
  idioma: string;
}
export interface CommunityCustomOnboardingAPII {
  forzarRevisionActualizarTarifa: Customizable;
  personalizacionParaApp: Customizable;
  personalizacionParaWeb: Customizable;
  emailDeContacto: string;
  telefonoDeContacto: string;
  colorPrimario: string;
  colorSecundario: string;
  politicaDePrivacidad: {
    es: string;
    ca: string;
  };
  condicionesOferta: {
    es: string;
    ca: string;
  };
  imagenes?: CustomizableImage[];
  // faqs?: FaqsApi[];
  horarioContacto: {
    horarios: Array<{
      franja: string;
      horas: string;
      idioma: string;
    }>;
  };
  tituloFinalizacionOnboarding: {
    es: string;
    ca: string;
  };
  mensajeFinalizacionOnboarding: {
    es: string;
    ca: string;
  };
  personalizacionOnboarding?: {
    tituloOnboarding: {
      es: string;
      ca: string;
    };
    subtituloOnboarding: {
      es: string;
      ca: string;
    };
    pieOnboarding: {
      es: string;
      ca: string;
    };
    parametersOnboarding: {
      forzarPasoDos: string;
      comunicacionesComerciales: string;
    };
    tituloPopUpInicial: {
      es: string;
      ca: string;
    };
    descripcionPopUpInicial: {
      es: string;
      ca: string;
    };
    paso1PopUpInicial: {
      es: string;
      ca: string;
    };
    paso2PopUpInicial: {
      es: string;
      ca: string;
    };
    paso3PopUpInicial: {
      es: string;
      ca: string;
    };
    paso4PopUpInicial: {
      es: string;
      ca: string;
    };
  };
}

export const translatableMockString: TranslatableJSON<string> = {
  es: '',
  ca: '',
};
export default class CommunityCustomOnboarding implements Serializable<CommunityCustomOnboarding> {
  forzarRevisionActualizarTarifa: boolean;

  personalizacionParaApp: boolean;

  personalizacionParaWeb: boolean;

  emailDeContacto: string;

  telefonoDeContacto: string;

  colorPrimario: string;

  colorSecundario: string;

  politicaDePrivacidad: {
    es: string;
    ca: string;
  };

  condicionesOferta: {
    es: string;
    ca: string;
  };

  imagenes: CustomizableImage[];

  // faqs: FaqsApi[];

  horarioContacto: {
    horarios: Array<{
      franja: string;
      horas: string;
      idioma: string;
    }>;
  };

  tituloFinalizacionOnboarding: {
    es: string;
    ca: string;
  };

  mensajeFinalizacionOnboarding: {
    es: string;
    ca: string;
  };

  personalizacionOnboarding: {
    tituloOnboarding: {
      es: string;
      ca: string;
    };
    subtituloOnboarding: {
      es: string;
      ca: string;
    };
    pieOnboarding: {
      es: string;
      ca: string;
    };
    parametersOnboarding: {
      forzarPasoDos: string;
      comunicacionesComerciales: string;
    };
    tituloPopUpInicial: {
      es: string;
      ca: string;
    };
    descripcionPopUpInicial: {
      es: string;
      ca: string;
    };
    paso1PopUpInicial: {
      es: string;
      ca: string;
    };
    paso2PopUpInicial: {
      es: string;
      ca: string;
    };
    paso3PopUpInicial: {
      es: string;
      ca: string;
    };
    paso4PopUpInicial: {
      es: string;
      ca: string;
    };
  };

  deserialize(input: CommunityCustomOnboardingAPII): CommunityCustomOnboarding {
    if (!input) {
      return this;
    }
    this.forzarRevisionActualizarTarifa = input.forzarRevisionActualizarTarifa === Customizable.SI;
    this.personalizacionParaApp = input.personalizacionParaApp === Customizable.SI;
    this.personalizacionParaWeb = input.personalizacionParaWeb === Customizable.SI;
    this.emailDeContacto = input.emailDeContacto;
    this.telefonoDeContacto = input.telefonoDeContacto;
    this.colorPrimario = input.colorPrimario;
    this.colorSecundario = input.colorSecundario;
    this.politicaDePrivacidad = {
      es: input.politicaDePrivacidad.es,
      ca: input.politicaDePrivacidad.ca,
    };
    this.condicionesOferta = {
      es: input.condicionesOferta.es,
      ca: input.condicionesOferta.ca,
    };
    this.imagenes = input.imagenes || [];
    // this.faqs = input.faqs || [];
    this.horarioContacto = input.horarioContacto || '';
    this.tituloFinalizacionOnboarding = {
      es: input.tituloFinalizacionOnboarding.es,
      ca: input.tituloFinalizacionOnboarding.ca,
    };
    this.mensajeFinalizacionOnboarding = {
      es: input.mensajeFinalizacionOnboarding.es,
      ca: input.mensajeFinalizacionOnboarding.ca,
    };
    // Deserializar la nueva propiedad "personalizacionOnboarding"
    this.personalizacionOnboarding = {
      tituloOnboarding: {
        es: input.personalizacionOnboarding?.tituloOnboarding.es || '',
        ca: input.personalizacionOnboarding?.tituloOnboarding.ca || '',
      },
      subtituloOnboarding: {
        es: input.personalizacionOnboarding?.subtituloOnboarding.es || '',
        ca: input.personalizacionOnboarding?.subtituloOnboarding.ca || '',
      },
      pieOnboarding: {
        es: input.personalizacionOnboarding?.pieOnboarding.es || '',
        ca: input.personalizacionOnboarding?.pieOnboarding.ca || '',
      },
      parametersOnboarding: {
        forzarPasoDos: input.personalizacionOnboarding?.parametersOnboarding?.forzarPasoDos || 'no',
        comunicacionesComerciales:
          input.personalizacionOnboarding?.parametersOnboarding?.comunicacionesComerciales || 'no',
      },
      tituloPopUpInicial: {
        es: input.personalizacionOnboarding?.tituloPopUpInicial?.es || '',
        ca: input.personalizacionOnboarding?.tituloPopUpInicial?.ca || '',
      },
      descripcionPopUpInicial: {
        es: input.personalizacionOnboarding?.descripcionPopUpInicial?.es || '',
        ca: input.personalizacionOnboarding?.descripcionPopUpInicial?.ca || '',
      },
      paso1PopUpInicial: {
        es: input.personalizacionOnboarding?.paso1PopUpInicial?.es || '',
        ca: input.personalizacionOnboarding?.paso1PopUpInicial?.ca || '',
      },
      paso2PopUpInicial: {
        es: input.personalizacionOnboarding?.paso2PopUpInicial?.es || '',
        ca: input.personalizacionOnboarding?.paso2PopUpInicial?.ca || '',
      },
      paso3PopUpInicial: {
        es: input.personalizacionOnboarding?.paso3PopUpInicial?.es || '',
        ca: input.personalizacionOnboarding?.paso3PopUpInicial?.ca || '',
      },
      paso4PopUpInicial: {
        es: input.personalizacionOnboarding?.paso4PopUpInicial?.es || '',
        ca: input.personalizacionOnboarding?.paso4PopUpInicial?.ca || '',
      },
    };
    return this;
  }

  serialize(): CommunityCustomOnboardingAPII {
    return {
      forzarRevisionActualizarTarifa: this.forzarRevisionActualizarTarifa ? Customizable.SI : Customizable.NO,
      personalizacionParaApp: this.personalizacionParaApp ? Customizable.SI : Customizable.NO,
      personalizacionParaWeb: this.personalizacionParaWeb ? Customizable.SI : Customizable.NO,
      emailDeContacto: this.emailDeContacto,
      telefonoDeContacto: this.telefonoDeContacto,
      colorPrimario: this.colorPrimario,
      colorSecundario: this.colorSecundario,
      politicaDePrivacidad: this.politicaDePrivacidad,
      condicionesOferta: { ca: this.condicionesOferta.ca, es: this.condicionesOferta.es },
      imagenes: this.imagenes,
      horarioContacto: this.horarioContacto,
      tituloFinalizacionOnboarding: {
        ca: this.tituloFinalizacionOnboarding.ca,
        es: this.tituloFinalizacionOnboarding.es,
      },
      mensajeFinalizacionOnboarding: {
        ca: this.mensajeFinalizacionOnboarding.ca,
        es: this.mensajeFinalizacionOnboarding.es,
      },
      // Deserializar la nueva propiedad "personalizacionOnboarding"
      personalizacionOnboarding: {
        tituloOnboarding: {
          es: this.personalizacionOnboarding.tituloOnboarding.es,
          ca: this.personalizacionOnboarding.tituloOnboarding.ca,
        },
        subtituloOnboarding: {
          es: this.personalizacionOnboarding.subtituloOnboarding.es,
          ca: this.personalizacionOnboarding.subtituloOnboarding.ca,
        },
        pieOnboarding: {
          es: this.personalizacionOnboarding.pieOnboarding.es,
          ca: this.personalizacionOnboarding.pieOnboarding.ca,
        },
        parametersOnboarding: {
          forzarPasoDos: this.personalizacionOnboarding?.parametersOnboarding.forzarPasoDos || '',
          comunicacionesComerciales:
            this.personalizacionOnboarding?.parametersOnboarding?.comunicacionesComerciales || 'no',
        },
        tituloPopUpInicial: {
          es: this.personalizacionOnboarding?.tituloPopUpInicial?.es || '',
          ca: this.personalizacionOnboarding?.tituloPopUpInicial?.ca || '',
        },
        descripcionPopUpInicial: {
          es: this.personalizacionOnboarding?.descripcionPopUpInicial?.es || '',
          ca: this.personalizacionOnboarding?.descripcionPopUpInicial?.ca || '',
        },
        paso1PopUpInicial: {
          es: this.personalizacionOnboarding?.paso1PopUpInicial?.es || '',
          ca: this.personalizacionOnboarding?.paso1PopUpInicial?.ca || '',
        },
        paso2PopUpInicial: {
          es: this.personalizacionOnboarding?.paso2PopUpInicial?.es || '',
          ca: this.personalizacionOnboarding?.paso2PopUpInicial?.ca || '',
        },
        paso3PopUpInicial: {
          es: this.personalizacionOnboarding?.paso3PopUpInicial?.es || '',
          ca: this.personalizacionOnboarding?.paso3PopUpInicial?.ca || '',
        },
        paso4PopUpInicial: {
          es: this.personalizacionOnboarding?.paso4PopUpInicial?.es || '',
          ca: this.personalizacionOnboarding?.paso4PopUpInicial?.ca || '',
        },
      },
    } as CommunityCustomOnboardingAPII;
  }

  setAttr<T>(key: string, value: T): void {
    Object.assign(this, { [key]: value });
  }
}
