import { SectionSubTitle, SectionTitle } from 'components';

import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { FormGroup, FormGroupProps, I18n } from 'core';
import { ReactComponent as DocumentsIcon } from 'assets/icons/Documents.svg';

import { CommunityCustomOnboarding, Customizable } from 'types/models';
import { Colors } from 'styles';
import { LoggingService } from 'services';

import { FORMGROUP_TYPES } from 'core/form/FormGroup/FormGroup';
import { SelectItemI } from 'components/NewInputs/Select/ElecsumSelect';
import { translatableLangs } from 'config/translatable-langs';
import { ToolsBarWithoutUrl } from 'pages/ModeTecnic/rol-comunidades/comunidades-detalle-page/tools-bar-without-url/tools-bar-without-url';
import { SaveDataHandle } from '../../comunidades-custom-onboarding-process-page';
import styles from './comunidades-custom-onboarding-form.module.scss';

interface ComunidadesCustomOnboardingFormProps {
  communityId: string;
  communityCustomOnboarding: CommunityCustomOnboarding;
  saveDetails: (customOnboarding: CommunityCustomOnboarding) => void;
}

const ComunidadesCustomOnboardingForm = forwardRef<SaveDataHandle, ComunidadesCustomOnboardingFormProps>(
  (props: ComunidadesCustomOnboardingFormProps, ref) => {
    const { communityCustomOnboarding, saveDetails } = props;
    const [customOnboarding, setCustomOnboarding] = useState<CommunityCustomOnboarding>();
    const [errors, setErrors] = useState({});
    const [currentLang, setCurrentLang] = useState<SelectItemI>(translatableLangs[0]);

    const customOnboardingData = JSON.parse(JSON.stringify(communityCustomOnboarding.serialize()));
    const validateForm = (): boolean => {
      setErrors({});
      return true;
    };

    function updateLanguageFields<T>(
      path: string,
      value: T,
      currentLangUp: { id: string },
      customOnboardingDataUp: any,
      communityCustomOnboardingUp: any,
    ) {
      let updatedValue: any = {};
      const { id: currentLangId } = currentLangUp;
      if (currentLangId === 'es') {
        updatedValue = {
          es: value as unknown as string,
          ca: customOnboardingDataUp[path]?.ca || '',
        };
      } else if (currentLangId === 'ca') {
        updatedValue = {
          es: customOnboardingDataUp[path]?.es || '',
          ca: value as unknown as string,
        };
      }

      const updatedCustomOnboardingData = {
        ...customOnboardingDataUp,
        [path]: updatedValue,
      };

      setCustomOnboarding(new CommunityCustomOnboarding().deserialize(updatedCustomOnboardingData));
      communityCustomOnboardingUp.setAttr(path, updatedValue);
    }

    function updateLanguageFieldsOnboarding<T>(
      path: string,
      value: T,
      currentLangUp: { id: string },
      customOnboardingDataUp: any,
      communityCustomOnboardingUp: any,
    ) {
      const currentLangId = currentLangUp.id;

      // Dividir la cadena `path` por el punto y obtener el segundo valor
      const pathSegments = path.split('.');
      const fieldName = pathSegments[1];

      const updatedValue = {
        ...(communityCustomOnboardingUp?.personalizacionOnboarding?.[fieldName] || {}),
        [currentLangId]: String(value), // Convertir a cadena de manera segura
      };

      const updatedPersonalizacionOnboarding = {
        ...communityCustomOnboardingUp?.personalizacionOnboarding,
        [fieldName]: updatedValue,
      };

      // Mantén todos los demás datos en `communityCustomOnboarding`
      const updatedCustomOnboardingData = {
        ...customOnboardingDataUp,
        personalizacionOnboarding: updatedPersonalizacionOnboarding,
      };

      // Actualiza `customOnboarding` con los datos actualizados
      setCustomOnboarding(new CommunityCustomOnboarding().deserialize(updatedCustomOnboardingData));
      communityCustomOnboardingUp.setAttr('personalizacionOnboarding', updatedPersonalizacionOnboarding);
    }
    function handleChange<T>(path: string, value: T): void {
      if (path === 'politicaDePrivacidad') {
        updateLanguageFields(path, value, currentLang, customOnboardingData, communityCustomOnboarding);
      } else if (path.includes('Onboarding')) {
        updateLanguageFieldsOnboarding(path, value, currentLang, customOnboardingData, communityCustomOnboarding);
      } else {
        const updatedCustomOnboardingData = {
          ...customOnboardingData,
          [path]: value,
        };
        setCustomOnboarding(new CommunityCustomOnboarding().deserialize(updatedCustomOnboardingData));
        communityCustomOnboarding.setAttr(path, value);
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    function handleCheckBox<T>(path: string, value: string, id: string, checked: boolean): void {
      communityCustomOnboarding.setAttr(path, checked);
      setCustomOnboarding(
        new CommunityCustomOnboarding().deserialize({
          ...customOnboardingData,
          [path]: checked ? Customizable.SI : Customizable.NO,
        }),
      );
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    function handleCheckBoxOnboarding<T>(path: string, checked: boolean): void {
      if (
        communityCustomOnboarding.personalizacionOnboarding.parametersOnboarding[
          path as keyof typeof communityCustomOnboarding.personalizacionOnboarding.parametersOnboarding
        ] === 'si'
      ) {
        communityCustomOnboarding.personalizacionOnboarding.parametersOnboarding[
          path as keyof typeof communityCustomOnboarding.personalizacionOnboarding.parametersOnboarding
        ] = 'no';
      } else {
        communityCustomOnboarding.personalizacionOnboarding.parametersOnboarding[
          path as keyof typeof communityCustomOnboarding.personalizacionOnboarding.parametersOnboarding
        ] = 'si';
      }
      // Update the "forzarPasoDos" property within parametersOnboarding

      setCustomOnboarding(
        new CommunityCustomOnboarding().deserialize({
          ...customOnboardingData,
          personalizacionOnboarding: {
            ...communityCustomOnboarding.personalizacionOnboarding, // Include the existing personalizacionOnboarding
          },
        }),
      );
    }

    const handleSubmit = (): void => {
      if (validateForm() && customOnboarding) {
        LoggingService.log('handleSubmit :: ok', { client: customOnboarding });
        saveDetails(customOnboarding);
      } else {
        LoggingService.log('handleSubmit :: errors', { client: customOnboarding, errors });
      }
    };

    const formFieldsCheckBoxes: Array<FormGroupProps> = [
      {
        label: I18n.t('ComunidadesCustomOnboardingProcess.form.labels.AppCustom'),
        type: 'checkbox',
        id: 'personalizacionParaApp',
        path: 'personalizacionParaApp',
        name: 'personalizacionParaApp',
        checked: communityCustomOnboarding?.personalizacionParaApp,
        value: communityCustomOnboarding?.personalizacionParaApp,
      },
      {
        label: I18n.t('ComunidadesCustomOnboardingProcess.form.labels.WebCustom'),
        type: 'checkbox',
        id: 'personalizacionParaWeb',
        path: 'personalizacionParaWeb',
        name: 'personalizacionParaWeb',
        checked: communityCustomOnboarding?.personalizacionParaWeb,
        value: communityCustomOnboarding?.personalizacionParaWeb,
      },
    ];

    const formFieldsPersonalizacionCheckBoxes: Array<FormGroupProps> = [
      {
        label: I18n.t('ComunidadesCustomOnboardingProcess.form.labels.forzarpaso2'),
        type: 'checkbox',
        id: 'forzarPasoDos',
        path: 'forzarPasoDos', // Update the path
        name: 'forzarPasoDos',
        checked: communityCustomOnboarding?.personalizacionOnboarding.parametersOnboarding.forzarPasoDos === 'si',
        value: communityCustomOnboarding?.personalizacionOnboarding.parametersOnboarding.forzarPasoDos,
      },
      {
        label: I18n.t('ComunidadesCustomOnboardingProcess.form.labels.comunicacionesComerciales'),
        type: 'checkbox',
        id: 'comunicacionesComerciales',
        path: 'comunicacionesComerciales', // Update the path
        name: 'comunicacionesComerciales',
        checked:
          communityCustomOnboarding?.personalizacionOnboarding.parametersOnboarding.comunicacionesComerciales === 'si',
        value: communityCustomOnboarding?.personalizacionOnboarding.parametersOnboarding.comunicacionesComerciales,
      },
    ];

    const formFields: Array<FormGroupProps> = [
      {
        label: I18n.t('ComunidadesCustomOnboardingProcess.form.labels.email'),
        type: 'string',
        id: 'emailDeContacto',
        path: 'emailDeContacto',
        name: 'emailDeContacto',
        placeholder: I18n.t('ComunidadesCustomOnboardingProcess.form.placeholder.email'),
        value: communityCustomOnboarding?.emailDeContacto,
      },
      {
        label: I18n.t('ComunidadesCustomOnboardingProcess.form.labels.telephone'),
        type: 'string',
        id: 'telefonoDeContacto',
        path: 'telefonoDeContacto',
        name: 'telefonoDeContacto',
        placeholder: I18n.t('ComunidadesCustomOnboardingProcess.form.placeholder.telephone'),
        value: communityCustomOnboarding?.telefonoDeContacto,
      },
      {
        label: I18n.t('ComunidadesCustomOnboardingProcess.form.labels.tituloFinalizacionOnboarding'),
        type: 'textarea',
        id: 'personalizacionOnboarding',
        path: 'personalizacionOnboarding.tituloOnboarding',
        name: 'personalizacionOnboarding',
        // placeholder: I18n.t('ComunidadesCustomOnboardingProcess.form.placeholder.personalizacionOnboarding.tituloOnboarding'),
        value:
          currentLang.id === 'es'
            ? communityCustomOnboarding?.personalizacionOnboarding.tituloOnboarding.es
            : communityCustomOnboarding?.personalizacionOnboarding.tituloOnboarding.ca,
        maxLenght: 500,
      },
      {
        label: I18n.t('ComunidadesCustomOnboardingProcess.form.labels.subtituloOnboarding'),
        type: 'textarea',
        id: 'personalizacionOnboarding.subtituloOnboarding',
        path: 'personalizacionOnboarding.subtituloOnboarding',
        name: 'personalizacionOnboarding.subtituloOnboarding',

        value:
          currentLang.id === 'es'
            ? communityCustomOnboarding?.personalizacionOnboarding.subtituloOnboarding.es
            : communityCustomOnboarding?.personalizacionOnboarding.subtituloOnboarding.ca,
        maxLenght: 500,
      },
      {
        label: I18n.t('ComunidadesCustomOnboardingProcess.form.labels.peuDePagina'),
        // label: I18n.t('ComunidadesDetallePage.detailData.labels.name'),
        type: 'textarea',
        id: 'personalizacionOnboarding.pieOnboarding',
        path: 'personalizacionOnboarding.pieOnboarding',
        name: 'personalizacionOnboarding.pieOnboarding',
        // placeholder: I18n.t('ComunidadesCustomOnboardingProcess.form.placeholder.personalizacionOnboarding.pieOnboarding'),
        value:
          currentLang.id === 'es'
            ? communityCustomOnboarding?.personalizacionOnboarding.pieOnboarding.es
            : communityCustomOnboarding?.personalizacionOnboarding.pieOnboarding.ca,
        maxLenght: 500,
      },
      {
        label: I18n.t('ComunidadesCustomOnboardingProcess.form.labels.privacyPolicy'),
        type: 'textarea',
        id: 'politicaDePrivacidad',
        path: 'politicaDePrivacidad',
        name: 'politicaDePrivacidad',
        placeholder: I18n.t('ComunidadesCustomOnboardingProcess.form.placeholder.privacyPolicy'),
        value:
          currentLang.id === 'es'
            ? communityCustomOnboarding?.politicaDePrivacidad.es
            : communityCustomOnboarding?.politicaDePrivacidad.ca,

        maxLenght: 500,
      },
      {
        label: I18n.t('ComunidadesCustomOnboardingProcess.form.labels.primaryColor'),
        type: FORMGROUP_TYPES.colorPicker,
        id: 'colorPrimario',
        path: 'colorPrimario',
        name: 'colorPrimario',
        placeholder: I18n.t('ComunidadesCustomOnboardingProcess.form.placeholder.primaryColor'),
        value: communityCustomOnboarding?.colorPrimario.trim(),
      },
      {
        label: I18n.t('ComunidadesCustomOnboardingProcess.form.labels.secondaryColor'),
        type: FORMGROUP_TYPES.colorPicker,
        id: 'colorSecundario',
        path: 'colorSecundario',
        name: 'colorSecundario',
        placeholder: I18n.t('ComunidadesCustomOnboardingProcess.form.placeholder.secondaryColor'),
        value: communityCustomOnboarding?.colorSecundario.trim(),
      },
    ];

    useImperativeHandle(ref, () => ({
      getData: () => {
        handleSubmit();
      },
    }));

    return (
      <div className={styles.root}>
        <div className={styles.flex}>
          <div className={styles.titulo}>
            <DocumentsIcon height={20} width={20} fill={Colors.COLOR_BLACK} className={styles.DocumentsIcon} />
            <SectionTitle text={I18n.t('ComunidadesCustomOnboardingProcess.form.title')} />
          </div>
          <div>
            <ToolsBarWithoutUrl
              langs={translatableLangs}
              selectedLang={currentLang}
              onChangeLanguage={(x) => setCurrentLang(x)}
            />
          </div>
        </div>
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.margin}>
            <SectionSubTitle text={I18n.t('ComunidadesCustomOnboardingProcess.form.section1')} />
          </div>
          {formFieldsCheckBoxes.map((formGroup: FormGroupProps) => (
            <FormGroup
              key={formGroup.id}
              label={formGroup.label}
              type={formGroup.type}
              id={formGroup.id}
              path={formGroup.path}
              name={formGroup.name}
              value={formGroup.value}
              checked={formGroup.checked}
              onChange={handleCheckBox}
            />
          ))}
          {formFieldsPersonalizacionCheckBoxes.map((formGroup: FormGroupProps) => (
            <FormGroup
              key={formGroup.id}
              label={formGroup.label}
              type={formGroup.type}
              id={formGroup.id}
              path={formGroup.path}
              name={formGroup.name}
              value={formGroup.value}
              checked={formGroup.checked}
              onChange={handleCheckBoxOnboarding}
            />
          ))}

          <div className={styles.containerForms}>
            <div className={styles.margin}>
              <SectionSubTitle text={I18n.t('ComunidadesCustomOnboardingProcess.form.section2')} />{' '}
            </div>

            <div className={styles.containerForm}>
              {formFields.map((formGroup: FormGroupProps) => (
                <FormGroup
                  key={formGroup.id}
                  label={formGroup.label}
                  type={formGroup.type}
                  id={formGroup.id}
                  path={formGroup.path}
                  name={formGroup.name}
                  placeholder={formGroup.placeholder}
                  value={formGroup.value}
                  required={formGroup.required}
                  onChange={handleChange}
                  readOnly={formGroup.readOnly}
                  className={formGroup.className}
                  maxLenght={20000}
                />
              ))}
            </div>

            {/* <div className={styles.containerForms}>
              <div className={styles.containerForm}>
                {formFieldsTitles.map((formGroup: FormGroupProps) => (
                  <FormGroup
                    key={formGroup.id}
                    label={formGroup.label}
                    type={formGroup.type}
                    id={formGroup.id}
                    path={formGroup.path}
                    name={formGroup.name}
                    placeholder={formGroup.placeholder}
                    value={formGroup.value}
                    required={formGroup.required}
                    onChange={handleChange}
                    readOnly={formGroup.readOnly}
                    className={formGroup.className}
                    maxLenght={20000}
                  />
                ))}
              </div>
            </div> */}
          </div>
        </form>
      </div>
    );
  },
);

export default ComunidadesCustomOnboardingForm;
