/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { FormGroup, FormGroupProps, I18n } from 'core';

import { SectionTitle, Toggle } from 'components';
import ButtonCsv from 'components/ButtonCsv/ButtonCsv';
import { ElecsumSelect } from 'components/NewInputs';
import { SelectItemI } from 'components/NewInputs/Select/ElecsumSelect';
import { TabSwitchIcons, TabSwitchOption } from 'components/TabSwitchIcons/TabSwitchIcons';
import MatcherPerfilesConsumoInstance from 'services/remote/MatcherPerfilesConsumoService';
import { InscritosModel } from 'types/models/inscritos';
import ElecsumInscritoSelect from 'components/NewInputs/Select/ElecsumInscritoSelect';
import ConfiguracionMiembro from 'types/models/configuracionMiembrosMatcher';
import CupsTooltipOferta from 'pages/SignUpPage/components/CupsTooltip/CupsTooltipOferta';
import CupsTooltipInputMatcher from 'pages/SignUpPage/components/CupsTooltip/CupsTooltipInputMatcher';
import styles from '../../AddMatcherPage.module.scss';

interface AddMemberPopUpProps {
  communitiesEnrolled: InscritosModel[];
  addPersonalMemberData: {
    nombre: string;
    cups: string;
    apellidos: string;
    email: string;
    nif: string;
    telefono: string;
    poblacion: string;
    direccion: string;
    domicilio: string;
    provincia: string;
    codigoPostal: string;
  };
  getConfiguracionMiembros: ConfiguracionMiembro[];

  addMember: {
    preferente: string;
    vip: string;
    bMin: number;
    bMax: number;
  };
  addConsumoAnual: {
    consumoAnual: number;
  };
  isDomestico: {
    domestico: string;
  };
  addPotencias: {
    terminoDeEnergiaP1: number;
    terminoDeEnergiaP2: number;
    terminoDeEnergiaP3: number;
    terminoDeEnergiaP4: number;
    terminoDeEnergiaP5: number;
    terminoDeEnergiaP6: number;
    consumPunta: number;
    consumPla: number;
    consumVall: number;
    compensacionImporte: number;
  };
  selectedPerfilConsumo: SelectItemI | undefined;
  onUpdateAddMember: (newAddMember: any) => void;
  onUpdateAddPotencias: (newAddPotencias: any) => void;
  onUpdateAddPersonalMemberData: (newAddPersonalMemberData: any) => void;
  onUpdateIsDomestico: (newIsDomestico: any) => void;
  onUpdatePerfilCSV: (newFile: any) => void;
  onUpdatePerfilSelect: (newFile: any) => void;
  onUpdateAddConsumoAnual: (newAddConsumoAnual: any) => void;
  getIdInscritos: (inscritoId: any) => void;
  mensajeError: {
    codigo: string;
    mensaje: string;
  };
  añoSeleccionado: number;
}

const AddMemberPopUp = ({
  communitiesEnrolled,
  addMember,
  addPotencias,
  addPersonalMemberData,
  isDomestico,
  selectedPerfilConsumo,
  addConsumoAnual,
  onUpdateAddMember,
  onUpdateAddPotencias,
  onUpdateAddPersonalMemberData,
  onUpdateIsDomestico,
  onUpdatePerfilCSV,
  onUpdatePerfilSelect,
  onUpdateAddConsumoAnual,
  getIdInscritos,
  mensajeError,
  añoSeleccionado,
  getConfiguracionMiembros,
}: AddMemberPopUpProps): JSX.Element => {
  const [perfilesDeConsumo, setPerfilesDeConsumo] = useState<Array<SelectItemI>>([]);
  const [selectedInscrito, setSelectedInscrito] = useState<InscritosModel | undefined>(undefined);
  const [isActive, setIsActive] = useState(false);

  const TextoBetaMax = [
    I18n.t('TooltipOferta.AddBetaMaxTitulo'),
    I18n.t('TooltipOferta.AddBetaMaxLista1'),
    I18n.t('TooltipOferta.AddBetaMaxLista2'),
  ];

  const TextoBetaMin = [
    I18n.t('TooltipOferta.AddBetaMinTitulo'),
    I18n.t('TooltipOferta.AddBetaMinLista1'),
    I18n.t('TooltipOferta.AddBetaMinLista2'),
  ];
  const TerminoEnergia = [
    I18n.t('TooltipOferta.TerminoEnergiaTitulo'),
    I18n.t('TooltipOferta.TerminoEnergiaLista1'),
    I18n.t('TooltipOferta.TerminoEnergiaLista2'),
  ];
  const TextoExcedente = [
    I18n.t('TooltipOferta.ExcedenteTitulo'),
    I18n.t('TooltipOferta.ExcedenteLista1'),
    I18n.t('TooltipOferta.ExcedenteLista2'),
  ];
  const POWERS_ARRAY_DOMESTICO = [
    {
      label: `${I18n.t('UpdateTarifaPage.consumPunta')}`,
      type: 'number',
      placeholder: '0',
      id: 'terminoDeEnergiaP1',
      path: 'terminoDeEnergiaP1',
      name: 'terminoDeEnergiaP1',
      value: addPotencias.terminoDeEnergiaP1,
      min: 0.01,
      step: 0.01,
    },
    {
      label: `${I18n.t('UpdateTarifaPage.consumPla')}`,
      type: 'number',
      placeholder: '0',
      id: 'terminoDeEnergiaP2',
      path: 'terminoDeEnergiaP2',
      name: 'terminoDeEnergiaP2',
      value: addPotencias.terminoDeEnergiaP2,
      min: 0.01,
      step: 0.01,
    },
    {
      label: `${I18n.t('UpdateTarifaPage.consumVall')}`,
      type: 'number',
      placeholder: '0',
      id: 'terminoDeEnergiaP3',
      path: 'terminoDeEnergiaP3',
      name: 'terminoDeEnergiaP3',
      value: addPotencias.terminoDeEnergiaP3,
      min: 0.01,
      step: 0.01,
    },
    {
      label: `${I18n.t('UpdateTarifaPage.compensacion')}`,
      type: 'number',
      placeholder: '0',
      id: 'compensacionImporte',
      path: 'compensacionImporte',
      name: 'compensacionImporte',
      value: addPotencias.compensacionImporte,
      tooltip: (
        <CupsTooltipInputMatcher
          title={I18n.t('UpdateTarifaPage.compensacion')}
          descripcion={TextoExcedente}
          direction="right"
        />
      ),
    },
  ];
  const POWERS_ARRAY_INDUSTRIAL = [
    {
      label: `${I18n.t('UpdateTarifaPage.p1')}`,
      type: 'number',
      placeholder: '0',
      id: 'terminoDeEnergiaP1',
      path: 'terminoDeEnergiaP1',
      name: 'terminoDeEnergiaP1',
      value: addPotencias.terminoDeEnergiaP1,
      min: 0.01,
      step: 0.01,
    },
    {
      label: `${I18n.t('UpdateTarifaPage.p2')}`,
      type: 'number',
      placeholder: '0',
      id: 'terminoDeEnergiaP2',
      path: 'terminoDeEnergiaP2',
      name: 'terminoDeEnergiaP2',
      value: addPotencias.terminoDeEnergiaP2,
      min: 0.01,
      step: 0.01,
    },
    {
      label: `${I18n.t('UpdateTarifaPage.p3')}`,
      type: 'number',
      placeholder: '0',
      id: 'terminoDeEnergiaP3',
      path: 'terminoDeEnergiaP3',
      name: 'terminoDeEnergiaP3',
      value: addPotencias.terminoDeEnergiaP3,
      min: 0.01,
      step: 0.01,
    },
    {
      label: `${I18n.t('UpdateTarifaPage.p4')}`,
      type: 'number',
      placeholder: '0',
      id: 'terminoDeEnergiaP4',
      path: 'terminoDeEnergiaP4',
      name: 'terminoDeEnergiaP4',
      value: addPotencias.terminoDeEnergiaP4,
      min: 0.01,
      step: 0.01,
    },
    {
      label: `${I18n.t('UpdateTarifaPage.p5')}`,
      type: 'number',
      placeholder: '0',
      id: 'terminoDeEnergiaP5',
      path: 'terminoDeEnergiaP5',
      name: 'terminoDeEnergiaP5',
      value: addPotencias.terminoDeEnergiaP5,
      min: 0.01,
      step: 0.01,
    },
    {
      label: `${I18n.t('UpdateTarifaPage.p6')}`,
      type: 'number',
      placeholder: '0',
      id: 'terminoDeEnergiaP6',
      path: 'terminoDeEnergiaP6',
      name: 'terminoDeEnergiaP6',
      value: addPotencias.terminoDeEnergiaP6,
      min: 0.01,
      step: 0.01,
    },
    {
      label: `${I18n.t('UpdateTarifaPage.compensacion')}`,
      type: 'number',
      placeholder: '0',
      id: 'compensacionImporte',
      path: 'compensacionImporte',
      name: 'compensacionImporte',
      value: addPotencias.compensacionImporte,
      tooltip: (
        <CupsTooltipInputMatcher
          title={I18n.t('UpdateTarifaPage.compensacion')}
          descripcion={TextoExcedente}
          direction="right"
        />
      ),
    },
  ];
  const formGroupModalData: Array<FormGroupProps> = [
    {
      label: I18n.t('PerfilListPage.addMatcher.preferent'),
      type: 'checkbox',
      id: 'preferente',
      path: 'preferente',
      name: 'preferente',
      value: addMember.preferente === 'si',
      checked: addMember.preferente === 'si',
    },
    {
      label: 'vip',
      type: 'checkbox',
      id: 'vip',
      path: 'vip',
      name: 'vip',
      value: addMember.vip === 'si',
      checked: addMember.vip === 'si',
    },
    {
      label: 'βMax',
      type: 'betas',
      placeholder: '0',
      id: 'bMax',
      path: 'bMax',
      name: 'bMax',
      value: addMember.bMax,
      min: 0,
      step: 0.001,
      max: 1,
      tooltip: <CupsTooltipInputMatcher title="βMax" descripcion={TextoBetaMax} direction="left" />,
    },
    {
      label: 'βMin',
      type: 'betas',
      placeholder: '0',
      id: 'bMin',
      path: 'bMin',
      name: 'bMin',
      value: addMember.bMin,
      min: 0,
      step: 0.001,
      max: 1,
      tooltip: <CupsTooltipInputMatcher title="βMin" descripcion={TextoBetaMin} direction="left" />,
    },
  ];
  const formGroupDomestico: Array<FormGroupProps> = [
    {
      label: I18n.t('InscritosDetalle.tipoConsumo'),
      type: 'select',
      id: 'domestico',
      path: 'domestico',
      name: 'domestico',
      placeholder: 'wwww',
      value: isDomestico.domestico,
      options: [
        { label: 'Domestic', value: 'domestico' },
        { label: 'Industrial', value: 'industrial' },
      ],
    },
  ];

  const formFieldsPersonalMemberData: Array<FormGroupProps> = [
    {
      label: I18n.t('InscritosDetalle.nombre'),

      type: 'string',
      id: 'nombre',
      path: 'nombre',
      name: 'nombre',
      value: addPersonalMemberData?.nombre,
      placeholder: '',
    },
    {
      label: I18n.t('InscritosDetalle.apellidos'),

      type: 'string',
      id: 'apellidos',
      path: 'apellidos',
      name: 'apellidos',
      value: addPersonalMemberData?.apellidos,
      placeholder: '',
    },
    {
      label: I18n.t('InscritosDetalle.Email'),
      type: 'string',
      id: 'email',
      path: 'email',
      name: 'email',
      value: addPersonalMemberData?.email,
      placeholder: '',
    },
    {
      label: I18n.t('InscritosDetalle.cups'),
      type: 'string',
      id: 'cups',
      path: 'cups',
      name: 'cups',
      value: addPersonalMemberData?.cups,
      placeholder: '',
    },
    {
      label: I18n.t('InscritosDetalle.Dni'),
      type: 'string',
      id: 'nif',
      path: 'nif',
      name: 'nif',
      placeholder: '',
      value: addPersonalMemberData?.nif,
    },
    {
      label: I18n.t('InscritosDetalle.telefon'),

      type: 'string',
      id: 'telefono',
      path: 'telefono',
      name: 'telefon',
      value: addPersonalMemberData?.telefono,
    },
    {
      label: I18n.t('InscritosDetalle.poblacion'),
      type: 'string',
      id: 'poblacion',
      path: 'poblacion',
      name: 'poblacion',
      value: addPersonalMemberData?.poblacion,
      placeholder: '',
    },
    {
      label: I18n.t('InscritosDetalle.provincia'),

      type: 'string',
      id: 'provincia',
      path: 'provincia',
      name: 'provincia',
      value: addPersonalMemberData?.provincia,
      placeholder: '',
    },
    {
      label: I18n.t('InscritosDetalle.Direccion'),

      type: 'string',
      id: 'direccion',
      path: 'direccion',
      name: 'direccion',
      value: addPersonalMemberData?.direccion,
      placeholder: '',
    },
    {
      label: I18n.t('InscritosDetalle.domicilio'),

      type: 'string',
      id: 'domicilio',
      path: 'domicilio',
      name: 'domicilio',
      value: addPersonalMemberData?.domicilio,
      placeholder: '',
    },
    {
      label: I18n.t('InscritosDetalle.codigoPostal'),

      type: 'string',
      id: 'codigoPostal',
      path: 'codigoPostal',
      name: 'codigoPostal',
      value: addPersonalMemberData?.codigoPostal,
      placeholder: '',
    },
  ];
  const formGroupConsumoAnual: Array<FormGroupProps> = [
    {
      label: I18n.t('PerfilListPage.ConsumoAnual'),
      type: 'number',
      placeholder: '0',
      id: 'consumoAnual',
      path: 'consumoAnual',
      name: 'consumoAnual',
      value: addConsumoAnual.consumoAnual,
    },
  ];

  // Actualizamos los datos del nuevo miembro
  async function handleCheckBox(path: string, value: string, id: string, checked: boolean): Promise<unknown> {
    const NewValue: string = checked ? 'si' : 'no';

    onUpdateAddMember({
      ...addMember,
      [path]: checked === undefined ? value : NewValue,
    });

    return true;
  }
  function handelChangeDomestico(path: string, value: string): void {
    if (selectedInscrito) {
      selectedInscrito.tipoConsumo = value;
    }
    onUpdateIsDomestico({ ...isDomestico, [path]: value });
  }
  // Actualizamos los datos de las potencias del nuevo miembro
  function handleChangePotencias(path: string, value: string): void {
    onUpdateAddPotencias({
      ...addPotencias,
      [path]: value,
    });
  }
  // Actualizamos los datos personales del nuevo miembro
  function handlePersonalMemberData(path: string, value: string): void {
    if (path !== 'codigoPostal' || (path === 'codigoPostal' && value.length < 6)) {
      onUpdateAddPersonalMemberData({
        ...addPersonalMemberData,
        [path]: value,
      });
    }
  }
  // Actualizamos el consumo anual del miembro
  function handleConsumoAnual(path: string, value: string): void {
    onUpdateAddConsumoAnual({
      ...addConsumoAnual,
      [path]: value,
    });
  }

  const handleFileSelect = (file: File) => {
    onUpdatePerfilCSV(file);
  };
  const [tabSelected, setTabSelected] = useState('Selecionar');

  const handleTabClick = (option: TabSwitchOption) => {
    setTabSelected(option.value as string);
  };
  // Funcion para renderizar el form con su onchange
  const renderFormGroup = (
    formGroup: FormGroupProps,
    onChange: (path: string, value: string, id: string, checked: boolean) => void,
  ) => (
    <FormGroup
      key={formGroup.id}
      label={formGroup.label}
      type={formGroup.type}
      id={formGroup.id}
      path={formGroup.path}
      name={formGroup.name}
      placeholder={formGroup.placeholder}
      value={formGroup.value}
      onChange={onChange}
      options={formGroup.options}
      disabled={formGroup.disabled}
      tabindex={formGroup.tabindex}
      error={formGroup.error}
      min={formGroup.min}
      max={formGroup.max}
      step={formGroup.step}
      tooltip={formGroup.tooltip}
    />
  );

  // Renderizar el form en caso Domestico
  const renderDomesticoSelect = () =>
    formGroupDomestico.map((formGroup: FormGroupProps) => (
      <div>
        <div className={styles.modalFlex}>{renderFormGroup(formGroup, handelChangeDomestico)}</div>
      </div>
    ));

  // Renderizar el form en caso Domestico
  const renderDomesticoPowers = () =>
    POWERS_ARRAY_DOMESTICO.map((formGroup: FormGroupProps) => (
      <div>
        <div className={styles.modalFlex}>{renderFormGroup(formGroup, handleChangePotencias)}</div>
      </div>
    ));

  const renderIndustrialPowers = () =>
    POWERS_ARRAY_INDUSTRIAL.map((formGroup: FormGroupProps) => (
      <div>
        <div className={styles.modalFlex}>{renderFormGroup(formGroup, handleChangePotencias)}</div>
      </div>
    ));

  // Renderizar el form en caso Domestico
  const renderPersonalMemberData = () =>
    formFieldsPersonalMemberData.map((formGroup: FormGroupProps) => (
      <div>
        <div className={styles.modalFlex}>{renderFormGroup(formGroup, handlePersonalMemberData)}</div>
      </div>
    ));

  // Renderizar el form en caso Domestico
  const renderModalData = () =>
    formGroupModalData.map((formGroup: FormGroupProps) => (
      <div>
        <div className={styles.modalFlex}>{renderFormGroup(formGroup, handleCheckBox)}</div>
      </div>
    ));

  // Renderizar el form Modal Data
  const renderConsumoAnual = () =>
    formGroupConsumoAnual.map((formGroup: FormGroupProps) => (
      <div>
        <div className={styles.modalFlex}>{renderFormGroup(formGroup, handleConsumoAnual)}</div>
      </div>
    ));

  const TABS_AVAILABLES: TabSwitchOption[] = [
    {
      id: '1',
      value: 'Selecionar',
      label: I18n.t('MatcherListPage.addMatcher.selectPerfil'),
    },
    {
      id: '2',
      value: 'Upload',
      label: `${I18n.t('MatcherListPage.addPerfilConsumo')}`,
    },
  ];

  const getAllPerfiles = () => {
    MatcherPerfilesConsumoInstance.getAllPerfilesDeConsumo().then((response) => {
      const perfilesSelect = response
        .filter((perfil) => perfil.infoDeConsumo.año === añoSeleccionado)
        .map((perfil) => ({
          id: perfil.id.toString(),
          value: perfil.nombre,
          label: perfil.nombre,
        }));
      setPerfilesDeConsumo(perfilesSelect);
    });
  };

  useEffect(() => {
    getAllPerfiles();
  }, []);

  // Renderizamos el componente
  return (
    <div className={styles.modalFlex}>
      <div className={styles.toogle_wrapper}>
        <p>{I18n.t('MatcherListPage.addMatcher.deseaInscrito')}</p>
        <Toggle value={isActive} onChange={setIsActive} />
      </div>
      {isActive ? (
        <div className={styles.modalFlex}>
          <ElecsumInscritoSelect
            minWidth="15rem"
            values={communitiesEnrolled.filter(
              (item1) => !getConfiguracionMiembros.some((item2) => item2.idInscrito === item1.id),
            )}
            selected={selectedInscrito}
            label={I18n.t('MatcherListPage.addMatcher.selectInscritos')}
            onChange={(el) => {
              setSelectedInscrito(el);
              getIdInscritos(el.id);

              const keys = Object.keys(el);
              const test = el as any;
              const updatedData = { ...addPersonalMemberData } as { [key: string]: string };
              const updatedaddMember: { [key: string]: string | number } = { ...addMember };
              const updatedaisDomestico = { ...isDomestico } as { [key: string]: string };
              const updatedaddPotencias: { [key: string]: string | number } = { ...addPotencias };
              const updatedaddConsumoAnual: { [key: string]: string | number } = { ...addConsumoAnual };

              keys.forEach((key) => {
                updatedData[key] = test[key];

                updatedaddMember[key] = test[key];
                updatedaisDomestico[key] = test[key];
                updatedaddPotencias[key] = test[key];
                updatedaddConsumoAnual[key] = test[key];
              });

              onUpdateAddPersonalMemberData(updatedData);

              onUpdateAddMember(updatedaddMember);
              onUpdateIsDomestico(updatedaisDomestico);
              onUpdateAddPotencias(updatedaddPotencias);

              onUpdateAddConsumoAnual(updatedaddConsumoAnual);
            }}
          />
        </div>
      ) : null}

      <SectionTitle className={styles.modalTitle} text={I18n.t('Dades personals')} />
      <div className={styles.modalGrid}>{renderPersonalMemberData()}</div>
      <div className={styles.tabModal}>
        <SectionTitle className={styles.modalTitle} text={I18n.t('MatcherListPage.addMatcher.perfilConsumo')} />
        <TabSwitchIcons
          value={TABS_AVAILABLES.find((option) => option.value === tabSelected) as TabSwitchOption}
          values={TABS_AVAILABLES}
          onChange={(item) => {
            handleTabClick(item);
          }}
        />
      </div>
      <div>
        {tabSelected === 'Selecionar' ? (
          <div className={styles.modalFlexRow}>
            <ElecsumSelect
              minWidth="17.5rem"
              values={perfilesDeConsumo}
              selected={selectedPerfilConsumo}
              label={I18n.t('MatcherListPage.addMatcher.selectPerfil')}
              onChange={(el) => {
                onUpdatePerfilSelect(el);
              }}
            />
            <div>{renderConsumoAnual()}</div>
          </div>
        ) : (
          <div className={styles.modalCsv}>
            <CupsTooltipOferta title={I18n.t('MatcherListPage.addPerfilConsumo')} />

            <ButtonCsv className={styles.csv_button} onFileSelect={handleFileSelect} />
            {mensajeError.codigo === '' ? null : (
              <div className={styles.errorMessage}>
                <p>{I18n.t(mensajeError.codigo)}</p>
                <p>{mensajeError.mensaje}</p>
              </div>
            )}
          </div>
        )}
      </div>

      <SectionTitle className={styles.modalTitle} text={I18n.t('Caracteristiques')} />
      <div className={styles.modalGrid4Columns}>{renderModalData()}</div>
      <div className={styles.modalGrid}>{renderDomesticoSelect()}</div>
      <div />
      <div>
        <div className={styles.modalTitle}>
          <CupsTooltipInputMatcher
            title={I18n.t('UpdateTarifaPage.preuTerme')}
            descripcion={TerminoEnergia}
            direction="right"
          />
        </div>
        {selectedInscrito?.tipoConsumo === 'domestico' ||
        (selectedInscrito === undefined && isDomestico.domestico === 'domestico') ? (
          <div className={styles.modalGrid}>{renderDomesticoPowers()}</div>
        ) : (
          <div className={styles.modalGrid}>{renderIndustrialPowers()}</div>
        )}
      </div>
    </div>
  );
};

export default AddMemberPopUp;
