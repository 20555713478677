import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import App from './App';

Sentry.init({
  dsn: 'https://50853472e05cfa3c82b91516fd33318a@sentry.eunoia.es/4',
  tracesSampleRate: 1.0,
  environment: process.env.REACT_APP_ENV,
});

ReactDOM.render(<App />, document.getElementById('root'));
