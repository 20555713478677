import { IsAdminGuard, LoggedInGuard } from 'navigation/guards';
import { BaseLayout, NoSidenavNoGradientLayout } from 'layouts';
import { AlertsPage, DashboardPage, UsersPage } from 'pages';
import {
  CIMClosuresPage,
  CIMDashboardPage,
  CIMDataExportPage,
  CIMDeviceInfoPage,
  CIMInvoiceSimulationPage,
  CIMMeasurementRegistersPage,
  CIMPowerOptimitzationPage,
} from 'pages/Common/CIM';
import { CupsListPage, UsuarioEgreenPage } from 'pages/ModeTecnic';
import { CUPSDetailPage } from 'pages/ModeTecnic/RolCUPS';
import InstallationCupsPage from 'pages/ModeTecnic/RolInstalacion/InstallationCupsPage';
import InstallationInfoPage from 'pages/ModeTecnic/RolInstalacion/InstallationInfoPage';
import InstallationProductionPage from 'pages/ModeTecnic/RolInstalacion/InstallationProductionPage/InstallationProductionPage';
import InstallationStudyPage from 'pages/ModeTecnic/RolInstalacion/InstallationStudyPage';
import UsuarioCUPSPage from 'pages/ModeTecnic/RolUsuario/UsuarioCUPSPage';
import UsuarioDetailPage from 'pages/ModeTecnic/RolUsuario/UsuarioDetailPage';
import {
  ComunidadesCustomOnboardingProcessPage,
  ComunidadesListPage,
  ComunitiesEnrolledListPage,
} from 'pages/ModeTecnic/rol-comunidades';
import { ComunidadesDetallePage } from 'pages/ModeTecnic/rol-comunidades/comunidades-detalle-page';
import { ComunidadesDetalleMembersPage } from 'pages/ModeTecnic/rol-comunidades/comunidades-detalle-members-page';
import { ComunidadesDetalleAnalyticsPage } from 'pages/ModeTecnic/rol-comunidades/comunidades-detalle-analytics-page';
// import { ComunidadesDetalleEnrolledPage } from 'pages/ModeTecnic/rol-comunidades/comunidades-detalle-enrolled-page';
import { ComunidadesInscritoDetalles } from 'pages/ModeTecnic/rol-comunidades/comunities-enrolled-list-page/components/comunidades-detalles-inscritos/comunidades-detalles-inscritos';
import HomePageAdmin from 'pages/ModeClient/HomePageAdmin';
import ChartsPageAdmin from 'pages/ModeClient/ChartsPageAdmin';
import AddUserPage from 'pages/AddUserPage';
import { ComunidadesCustomOnboardingProcessPageOnboarding } from 'pages/ModeTecnic/rol-comunidades/comunidades-custom-onboarding-process-page/comunidades-custom-onboarding-process-page-onboarding';
import { ComunidadesCustomOnboardingProcessPageHorario } from 'pages/ModeTecnic/rol-comunidades/comunidades-custom-onboarding-process-page/comunidades-custom-onboarding-process-page-horario';
import { ComunidadesCustomOnboardingProcessPageFaqs } from 'pages/ModeTecnic/rol-comunidades/comunidades-custom-onboarding-process-page/comunidades-custom-onboarding-process-page-faqs';
import { ComunidadesCustomOnboardingProcessPageTipoDecontacto } from 'pages/ModeTecnic/rol-comunidades/comunidades-custom-onboarding-process-page/comunidades-custom-onboarding-process-page-tipoDeContacto';
import { ComunidadesCustomOnboardingProcessPageOferta } from 'pages/ModeTecnic/rol-comunidades/comunidades-custom-onboarding-process-page/comunidades-custom-onboarding-process-page-oferta';
import { TarifasListPage } from 'pages/ModeTecnic/TarifasListPage/TarifasListPage';
import { TarifaInscritoDetalles } from 'pages/ModeTecnic/TarifasListPage/tarifa-detalle/tarifa-detalles-inscritos';
import { MatcherListPage } from 'pages/ModeTecnic/MatcherListPage/MatcherListPage';
import AddMatcherPage from 'pages/AddMatcherPage';
import { PerfileListPage } from 'pages/ModeTecnic/PerfileListPage/PerfileListPage';
import { PerfilDetalle } from 'pages/ModeTecnic/PerfileListPage/perfil-detalle/perfil-detalles';
import { ComunidadesCustomOnboardingProcessPageConfirmacionMensaje } from 'pages/ModeTecnic/rol-comunidades/comunidades-custom-onboarding-process-page/comunidades-custom-onboarding-process-page-confirmacionMensaje';
import { ComunidadesCustomOnboardingProcessPageOtros } from 'pages/ModeTecnic/rol-comunidades/comunidades-custom-onboarding-process-page/comunidades-custom-onboarding-process-page-otros';
import { ComunidadesDetallePromotorPage } from 'pages/ModeTecnic/rol-comunidades/comunidades-detalle-promotor-page';
import { ComunidadesCustomOnboardingProcessPageInformeAutomatico } from 'pages/ModeTecnic/rol-comunidades/comunidades-custom-onboarding-process-page/comunidades-custom-onboarding-process-page-informe-automatico';
import InstallationListPage from 'pages/ModeTecnic/InstallationListPage';
import { ComunidadesCustomOnboardingProcessPagePopUp } from 'pages/ModeTecnic/rol-comunidades/comunidades-custom-onboarding-process-page/comunidades-custom-onboarding-process-page-popup-inicial';
import RolesUserPage from 'pages/RolesUserPage';
import RolesUserPageDetalle from 'pages/RolesUserPageDetalle';
import UsuarioRolPage from 'pages/ModeTecnic/RolUsuario/UsuarioRolPage';
import { RouteInterface } from '../../types/interfaces';

const routes: Array<RouteInterface> = [
  {
    path: '/tecnic',
    name: 'Tecnic',
    children: [
      {
        path: '/',
        exact: true,
        name: 'Default Tecnic',
        redirect: '/dashboard',
      },
      {
        path: '/dashboard',
        layout: NoSidenavNoGradientLayout,
        component: DashboardPage,
        name: 'Dashboard',
        guards: [LoggedInGuard, IsAdminGuard],
      },
      {
        path: '/adduser',
        layout: NoSidenavNoGradientLayout,
        component: AddUserPage,
        exact: true,
        name: 'AddUser',
        guards: [LoggedInGuard, IsAdminGuard],
      },
      {
        path: '/roles',
        layout: BaseLayout,
        component: RolesUserPage,
        exact: true,
        name: 'roles',
        guards: [LoggedInGuard, IsAdminGuard],
      },
      {
        path: '/roles/:rolesId/',
        layout: BaseLayout,
        component: RolesUserPageDetalle,
        exact: true,
        name: 'roles',
        guards: [LoggedInGuard, IsAdminGuard],
      },
      {
        path: '/users',
        layout: NoSidenavNoGradientLayout,
        component: UsersPage,
        name: 'Users',
        guards: [LoggedInGuard, IsAdminGuard],
      },
      {
        path: '/cups',
        exact: true,
        layout: NoSidenavNoGradientLayout,
        component: CupsListPage,
        name: 'Cups',
        guards: [LoggedInGuard, IsAdminGuard],
      },
      {
        path: '/installation',
        exact: true,
        layout: NoSidenavNoGradientLayout,
        component: InstallationListPage,
        name: 'Installation',
        guards: [LoggedInGuard, IsAdminGuard],
      },
      {
        path: '/alerts',
        name: 'Alerts',
        component: AlertsPage,
        layout: NoSidenavNoGradientLayout,
        guards: [IsAdminGuard],
      },
      // ROL Instalacion
      {
        path: '/installation/:installationID/',
        name: 'Default',
        exact: true,
        layout: BaseLayout,
        redirect: '/dashboard',
      },
      {
        path: '/installation/:installationID/cups',
        name: 'Installation CUPS',
        exact: true,
        layout: BaseLayout,
        component: InstallationCupsPage,
      },
      {
        path: '/installation/:installationID/installation',
        name: 'Installation Detail',
        exact: true,
        layout: BaseLayout,
        component: InstallationInfoPage,
      },
      {
        path: '/installation/:installationID/study',
        name: 'Installation Study',
        exact: true,
        layout: BaseLayout,
        component: InstallationStudyPage,
      },
      {
        path: '/installation/:installationID/production',
        name: 'Installation Production',
        exact: true,
        layout: BaseLayout,
        component: InstallationProductionPage,
      },

      // ROL Cups
      {
        path: '/cups/:cupsID/detail',
        name: 'CUPS Detail',
        exact: true,
        layout: BaseLayout,
        component: CUPSDetailPage,
      },
      {
        path: '/cups/:cupsID/cim/dashboard',
        name: 'CUPS CIM Dashboard',
        exact: true,
        layout: BaseLayout,
        component: CIMDashboardPage,
      },
      {
        path: '/cups/:cupsID/dashboard',
        layout: BaseLayout,
        component: HomePageAdmin,
        name: 'Dashboard test',
        exact: true,
      },
      {
        path: '/cups/:cupsID/graficos',
        layout: BaseLayout,
        component: ChartsPageAdmin,
        name: 'Charts',
      },
      {
        path: '/cups/:cupsID/cim/device-info',
        name: 'CUPS CIM Device Info',
        exact: true,
        layout: BaseLayout,
        component: CIMDeviceInfoPage,
      },
      {
        path: '/cups/:cupsID/cim/measurement-registers',
        name: 'CUPS CIM Dashboard',
        exact: true,
        layout: BaseLayout,
        component: CIMMeasurementRegistersPage,
      },
      {
        path: '/cups/:cupsID/cim/invoice-simulation',
        name: 'CUPS CIM Dashboard',
        exact: true,
        layout: BaseLayout,
        component: CIMInvoiceSimulationPage,
      },
      {
        path: '/cups/:cupsID/cim/measurement-devices',
        name: 'CUPS CIM Measurement Devices',
        exact: true,
        layout: BaseLayout,
        component: CIMDeviceInfoPage,
      },
      {
        path: '/cups/:cupsID/cim/power-optimization',
        name: 'CUPS CIM Dashboard',
        exact: true,
        layout: BaseLayout,
        component: CIMPowerOptimitzationPage,
      },
      {
        path: '/cups/:cupsID/cim/closures',
        name: 'CUPS CIM Dashboard',
        exact: true,
        layout: BaseLayout,
        component: CIMClosuresPage,
      },
      {
        path: '/cups/:cupsID/cim/data-export',
        name: 'CUPS CIM Dashboard',
        exact: true,
        layout: BaseLayout,
        component: CIMDataExportPage,
      },
      // ROL USUARI
      {
        path: '/user/:userID/data',
        name: 'User Data',
        exact: true,
        layout: BaseLayout,
        component: UsuarioDetailPage,
        guards: [LoggedInGuard],
      },
      {
        path: '/user/:userID/cups',
        name: 'User CUPS',
        exact: true,
        layout: BaseLayout,
        component: UsuarioCUPSPage,
      },
      {
        path: '/user/:userID/elecsum-green',
        name: 'User Elecsum Green',
        exact: true,
        layout: BaseLayout,
        component: UsuarioEgreenPage,
      },
      {
        path: '/user/:userID/rol',
        name: 'User Data',
        exact: true,
        layout: BaseLayout,
        component: UsuarioRolPage,
        guards: [LoggedInGuard],
      },

      // Rol comunidades
      {
        path: '/communities',
        name: 'Comunidaes',
        component: ComunidadesListPage,
        layout: NoSidenavNoGradientLayout,
        guards: [IsAdminGuard],
        exact: true,
      },
      {
        path: '/communities/:communityID',
        name: 'Comunidaes Detalle',
        component: ComunidadesDetallePage,
        layout: BaseLayout,
        exact: true,
        guards: [IsAdminGuard],
      },
      {
        path: '/communities/:communityID/promotores',
        name: 'Comunidaes Detalle',
        component: ComunidadesDetallePromotorPage,
        layout: BaseLayout,
        exact: true,
        guards: [IsAdminGuard],
      },
      {
        path: '/communities/:communityID/detail',
        name: 'Comunidades Detalle',
        component: ComunidadesDetallePage,
        layout: BaseLayout,
        exact: true,
        guards: [IsAdminGuard],
      },
      {
        path: '/communities/:communityID/members',
        name: 'Comunidades Detalle',
        component: ComunidadesDetalleMembersPage,
        layout: BaseLayout,
        guards: [IsAdminGuard],
        exact: true,
      },
      {
        path: '/communities/:communityID/enrolled',
        name: 'Comunidades Detalle',
        component: ComunitiesEnrolledListPage,
        layout: BaseLayout,
        guards: [IsAdminGuard],
        exact: true,
      },
      {
        path: '/communities/:communityID/customization',
        name: 'Comunidades Custom Onboarding Process',
        component: ComunidadesCustomOnboardingProcessPage,
        layout: BaseLayout,
        guards: [IsAdminGuard],
        exact: true,
      },
      {
        path: '/communities/:communityID/onboarding',
        name: 'Comunidades Custom Onboarding Process',
        exact: true,
        redirect: '/home',
      },
      {
        path: '/communities/:communityID/onboarding/onboarding',
        name: 'Comunidades Custom Onboarding Process',
        component: ComunidadesCustomOnboardingProcessPageOnboarding,
        layout: BaseLayout,
        guards: [IsAdminGuard],
        exact: true,
      },
      {
        path: '/communities/:communityID/onboarding/horarios',
        name: 'Comunidades Custom Onboarding Process',
        component: ComunidadesCustomOnboardingProcessPageHorario,
        layout: BaseLayout,
        guards: [IsAdminGuard],
        exact: true,
      },
      {
        path: '/communities/:communityID/onboarding/contact',
        name: 'Comunidades Custom Onboarding Process',
        component: ComunidadesCustomOnboardingProcessPageTipoDecontacto,
        layout: BaseLayout,
        guards: [IsAdminGuard],
        exact: true,
      },
      {
        path: '/communities/:communityID/onboarding/popup',
        name: 'Comunidades Custom Onboarding Process',
        component: ComunidadesCustomOnboardingProcessPagePopUp,
        layout: BaseLayout,
        guards: [IsAdminGuard],
        exact: true,
      },
      {
        path: '/communities/:communityID/onboarding/faqs',
        name: 'Comunidades Custom Onboarding Process',
        component: ComunidadesCustomOnboardingProcessPageFaqs,
        layout: BaseLayout,
        guards: [IsAdminGuard],
        exact: true,
      },
      {
        path: '/communities/:communityID/onboarding/informe-automatico',
        name: 'Comunidades Custom Onboarding Process',
        component: ComunidadesCustomOnboardingProcessPageInformeAutomatico,
        layout: BaseLayout,
        guards: [IsAdminGuard],
        exact: true,
      },
      {
        path: '/communities/:communityID/onboarding/oferta',
        name: 'Comunidades Custom Onboarding Process',
        component: ComunidadesCustomOnboardingProcessPageOferta,
        layout: BaseLayout,
        guards: [IsAdminGuard],
        exact: true,
      },
      {
        path: '/communities/:communityID/onboarding/confirmacionMensaje',
        name: 'Comunidades Custom Onboarding Process',
        component: ComunidadesCustomOnboardingProcessPageConfirmacionMensaje,
        layout: BaseLayout,
        guards: [IsAdminGuard],
        exact: true,
      },
      {
        path: '/communities/:communityID/onboarding/otros',
        name: 'Comunidades Custom Onboarding Process',
        component: ComunidadesCustomOnboardingProcessPageOtros,
        layout: BaseLayout,
        guards: [IsAdminGuard],
        exact: true,
      },
      {
        path: '/communities/:communityID/analytics',
        name: 'Comunidaes Detalle',
        component: ComunidadesDetalleAnalyticsPage,
        layout: BaseLayout,
        guards: [IsAdminGuard],
        exact: true,
      },
      // Inscritos pendientes de validar
      {
        path: '/inscritos',
        name: 'Inscritos',
        component: ComunitiesEnrolledListPage,
        layout: NoSidenavNoGradientLayout,
        guards: [IsAdminGuard],
        exact: true,
      },
      {
        path: '/communities/:communityID/:inscritosID/inscritos',
        name: 'Installation Detail',
        layout: BaseLayout,
        guards: [IsAdminGuard],
        exact: true,
        component: ComunidadesInscritoDetalles,
      },
      {
        path: '/inscritos/:inscritosID/inscritos',
        name: 'Installation Detail',
        layout: BaseLayout,
        guards: [IsAdminGuard],
        exact: true,
        component: ComunidadesInscritoDetalles,
      },
      // Inscritos pendientes de validar
      {
        path: '/tarifas',
        name: 'Tarifas',
        component: TarifasListPage,
        layout: NoSidenavNoGradientLayout,
        guards: [IsAdminGuard],
        exact: true,
      },
      {
        path: '/tarifas/:tarifaID/tarifas',
        name: 'Installation Detail',
        layout: BaseLayout,
        guards: [IsAdminGuard],
        exact: true,
        component: TarifaInscritoDetalles,
      },
      // Lista Configuracion Matcher
      {
        path: '/matcher',
        name: 'Matcher',
        component: MatcherListPage,
        layout: BaseLayout,
        guards: [LoggedInGuard, IsAdminGuard],
        exact: true,
      },
      {
        path: '/matcher/:MatcherId/edit',
        layout: BaseLayout,
        component: AddMatcherPage,
        exact: true,
        name: 'AddMatcher',
        guards: [LoggedInGuard, IsAdminGuard],
      },
      {
        path: '/matcher/preferencias/perfiles-consumo',
        name: 'Matcher',
        component: PerfileListPage,
        layout: BaseLayout,
        guards: [LoggedInGuard, IsAdminGuard],
        exact: true,
      },
      {
        path: '/matcher/preferencias/perfiles-consumo/:perfilId',
        name: 'Matcher',
        component: PerfilDetalle,
        layout: BaseLayout,
        guards: [LoggedInGuard, IsAdminGuard],
        exact: true,
      },
    ],
  },
];

export default routes;
