import { AxiosResponse } from 'axios';
import configuration from 'config';
import { TranslatableJSON } from 'types/interfaces/polyglot';
import { CommunityCustomOnboarding, CommunityCustomOnboardingAPII, CustomizableImageType } from 'types/models';
import { Customizable, FaqsApi } from 'types/models/communityCustomOnboarding';
import { FaqsCustomOnboardingAPII } from 'types/models/faqsCustomOnboarding';
import { TipodecontactoAPII } from 'types/models/tipodecontacto';
import CommunityCustomOnboardingCUPS, {
  CommunityCustomOnboardingCUPSAPII,
} from 'types/models/communityCustomOnboardingCups';
import apiService from './ApiService';

export type CommunityCustomOnboardingUpdate = Omit<CommunityCustomOnboardingAPII, 'imagenes'>;
export interface TipodecontactoPost {
  emailContacto: string;
  tipoContacto: TranslatableJSON<string>;
}
export class CommunityCustomOnboardingService {
  readonly http = apiService;

  private apiUrl = configuration.apiUrl;

  async getCommunityCustomOnboarding(id: string): Promise<CommunityCustomOnboarding> {
    const url = `${this.apiUrl}/ce/personalizacion/${id}/`;
    const response: AxiosResponse<{ datos: CommunityCustomOnboardingAPII }> = await this.http.get(url);
    const result = new CommunityCustomOnboarding().deserialize(response.data.datos);

    return result;
  }

  async updateCommunityCustomOnboarding(
    communityId: string,
    customOnboarding: CommunityCustomOnboardingUpdate,
  ): Promise<string> {
    const url = `${this.apiUrl}/ce/personalizacion/${communityId}/`;

    const response: AxiosResponse<string> = await this.http.patch(url, customOnboarding);

    return response.data;
  }

  async updateCommunityCustomOnboardingHorarios(communityId: string, communityCustomOnboarding: any): Promise<string> {
    const data = {
      personalizacionParaApp: communityCustomOnboarding.personalizacionParaApp === Customizable.SI,
      personalizacionParaWeb: communityCustomOnboarding.personalizacionParaWeb === Customizable.SI,
      emailDeContacto: communityCustomOnboarding.emailDeContacto,
      telefonoDeContacto: communityCustomOnboarding.telefonoDeContacto,
      colorPrimario: communityCustomOnboarding.colorPrimario.trim(),
      colorSecundario: communityCustomOnboarding.colorSecundario.trim(),
      politicaDePrivacidad: communityCustomOnboarding.politicaDePrivacidad,
      horarioContacto: communityCustomOnboarding.horarioContacto,
    };
    const url = `${this.apiUrl}/ce/personalizacion/${communityId}/`;

    const response: AxiosResponse<string> = await this.http.patch(url, data);

    return response.data;
  }

  async addImage(id: string, file: File, type: CustomizableImageType, selectedType: string): Promise<number> {
    const url = `${this.apiUrl}/ce/personalizacion/${id}/imagen/`;

    const imageData = new window.FormData();

    imageData.append('tipo', type);
    imageData.append('imagen', file);
    imageData.append('mostrar', selectedType);
    const response: AxiosResponse<{ status: number }> = await this.http.post(url, imageData);

    return response.status;
  }

  async deleteImage(communityId: string, imgId: string): Promise<unknown> {
    const url = `${this.apiUrl}/ce/personalizacion/${communityId}/imagen/${imgId}/`;

    const response: AxiosResponse<number> = await this.http.delete(url);

    return response;
  }

  async addFaqsComunity(communityId: string, faqs: FaqsApi): Promise<number> {
    const url = `${this.apiUrl}/ce/personalizacion/${communityId}/faq/`;

    const object = {
      pregunta: faqs.pregunta,
      respuesta: faqs.respuesta,
      idioma: faqs.idioma,
    };
    const response: AxiosResponse<{ status: number }> = await this.http.post(url, object);

    return response.status;
  }

  async deleteFaqsComunity(communityId: string, faqsId: string): Promise<unknown> {
    const url = `${this.apiUrl}/ce/personalizacion/${communityId}/faq/${faqsId}`;

    const response: AxiosResponse<number> = await this.http.delete(url);

    return response;
  }

  async getTipoDeContacto(id: string): Promise<any> {
    const url = `${this.apiUrl}/ce/personalizacion/${id}/tipo-contacto/`;

    const response: AxiosResponse<{ datos: TipodecontactoAPII }> = await this.http.get(url);

    return response.data.datos;
  }

  async AddtipoDeContacto(id: string, tipodecontacto: TipodecontactoAPII): Promise<string> {
    const url = `${this.apiUrl}/ce/personalizacion/${id}/tipo-contacto/`;

    const response: AxiosResponse<string> = await this.http.post(url, tipodecontacto);

    return response.data;
  }

  async deletetipoDeContacto(idComunidad: string, idTipoContacto: string): Promise<unknown> {
    const url = `${this.apiUrl}/ce/personalizacion/${idComunidad}/tipo-contacto/${idTipoContacto}`;

    const response: AxiosResponse<number> = await this.http.delete(url);

    return response;
  }

  async getFAQSCommunityCustomOnboarding(id: string): Promise<any> {
    const url = `${this.apiUrl}/ce/personalizacion/${id}/faq/`;
    const response: AxiosResponse<{ datos: FaqsCustomOnboardingAPII }> = await this.http.get(url);

    const result = response.data.datos;

    return result;
  }

  async deleteFaq(idComunidad: string, idFaq: string): Promise<unknown> {
    const url = `${this.apiUrl}/ce/personalizacion/${idComunidad}/faq/${idFaq}`;

    const response: AxiosResponse<number> = await this.http.delete(url);

    return response;
  }

  async getCommunityCustomPersonalization(cups: string): Promise<CommunityCustomOnboardingCUPS> {
    const url = `${this.apiUrl}/ce/personalizacion/por-cups/${cups}/`;
    const response: AxiosResponse<{ datos: CommunityCustomOnboardingCUPSAPII }> = await this.http.get(url);

    const result = new CommunityCustomOnboardingCUPS().deserialize(response.data.datos);

    return result;
  }
}

const CommunityCustomOnboardingServiceInstance = new CommunityCustomOnboardingService();
export default CommunityCustomOnboardingServiceInstance;
