/* eslint-disable react/no-array-index-key */
import React from 'react';
import { I18n } from 'core';
import FullPageSyncLoader from 'components/FullPageSyncLoader';
// import ConfiguracionMatcher from 'types/models/ConfiguracionMatcher';
import styles from './table-matcher.module.scss';

interface TableMatcherProps {
  headerMatcherHeader: string[];
  // Esto sera los miembros obtenidos que tenga es a configuracion
  texAling: 'center' | 'left'; // Utiliza el operador | para definir una unión de tipos
  miembrosMatcher: Array<any>;
  renderRow: (data: any, index: any) => JSX.Element;
}
const TableMatcher = ({
  headerMatcherHeader,
  miembrosMatcher,
  renderRow,
  texAling,
}: TableMatcherProps): JSX.Element => (
  <table>
    <thead>
      <tr style={{ textAlign: texAling }}>
        {headerMatcherHeader.map(
          (
            headerName: string,
            index: number, // Add index as a second argument
          ) => (
            <th style={{ textAlign: texAling }} key={index}>
              {headerName}
            </th> // Assign unique key using the index
          ),
        )}
      </tr>
    </thead>
    <tbody>
      {miembrosMatcher ? (
        (() => {
          if (miembrosMatcher && miembrosMatcher.length > 0) {
            return miembrosMatcher.sort((a, b) => a.id - b.id).map((rowData, index) => renderRow(rowData, index));
          }
          return (
            <tr className={styles.Sinboder} key="nodata">
              <td className={styles.nodata}>{I18n.t('noData')}</td>
            </tr>
          );
        })()
      ) : (
        <FullPageSyncLoader key="loader" icon /> // Add a unique key for the loader component
      )}
    </tbody>
  </table>
);

export default TableMatcher;
