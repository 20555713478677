import React from 'react';

interface MiembroRowProps {
  miembro: any;
}

const MiembroRow = ({ miembro }: MiembroRowProps): JSX.Element => (
  <tr key={miembro.id} style={{ textAlign: 'center' }}>
    <td>{miembro.nombre}</td>
    <td>{miembro.apellidos}</td>
    <td>{miembro.autoconsumoEuro ? miembro?.autoconsumoEuro.toFixed(2) : 0} €</td>
    <td>{miembro.excedenteEuro ? miembro?.excedenteEuro.toFixed(2) : 0} €</td>
    <td>{miembro.ahorrosEuro ? miembro?.ahorrosEuro.toFixed(2) : 0} €</td>
  </tr>
);

export default MiembroRow;
