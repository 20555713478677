/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useState, MouseEvent } from 'react';

import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { nav, isRouteActive } from 'navigation';
import { authServiceInstance, cupsServiceInstance } from 'services';
import { CupsInfo, User } from 'types/models';
import classNames from 'classnames';

import { NavLink } from 'react-router-dom';

import { Colors } from 'styles';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/ArrowRight.svg';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/ArrowDown.svg';
import { useRole } from 'hooks';
import createClientNavigationLinks from 'navigation/navigabilities/ModeClientDefaultNavegabilities';
import createTecnicCUPSNavigationLinks from 'navigation/navigabilities/ModeTecnicCUPSNavegabilities';
import createTecnicInstallationNavigationLinks from 'navigation/navigabilities/ModeTecnicInstallationNavegabilities';
import createTecnicUserNavigationLinks from 'navigation/navigabilities/ModeTecnicUserNavegabilities';
import createTecnicCommunitiesNavigationLinks from 'navigation/navigabilities/ModeTecnicCommunitiesNavegabilities';

import { Roles } from 'types/enums';
import { useSelector } from 'react-redux';
import { GlobalStoreState } from 'types/state';
import createPromotorNavigationLinks from 'navigation/navigabilities/ModePromotorDefaultNavegabilities';
import createMatcherNavigationLinks from 'navigation/navigabilities/ModeTecnicMatcherNavegabilities';
import createRolPermisionNavigationLinks from 'navigation/navigabilities/ModeTecnicRolPermisionNavegabilities';
import usersService from 'services/remote/UsersService';
import styles from './Sidenav.module.scss';

const ICON_SIZE = 16;

export interface NavItemI {
  url?: string;
  icon?: JSX.Element | null;
  activeIcon?: JSX.Element | null;
  name?: string;
  admin?: boolean;
  client?: boolean;
  divider?: boolean;
  title?: boolean;
  hidden?: boolean;
  noDropDown?: boolean;
  children?: Array<NavItemI>;
}

interface NavItemProps {
  item: NavItemI;
  user?: User | null;
}

const NavItemLink = (props: NavItemI): JSX.Element => {
  const [isActive, setIsActive] = useState(false);
  const { url, icon, activeIcon, name } = props;

  useEffect(() => {
    if (url) {
      setIsActive(isRouteActive(url, true));
    }
  }, [url]);

  return (
    <li className={classNames(styles.NavItemLink, { [styles.active]: isActive })}>
      <NavLink exact className={styles.link} to={url || ''} activeClassName={styles.active}>
        {isActive ? activeIcon : icon}
        <span>{name}</span>
      </NavLink>
    </li>
  );
};

const NavItemDivider = (): JSX.Element => <li className={styles.NavItemDivider} />;

const NavItemTitle = (props: NavItemI): JSX.Element => {
  const { icon, name } = props;

  return (
    <li className={styles.NavItemTitle}>
      {icon || null}
      <span>{name}</span>
    </li>
  );
};

const NavItemDropdown = (props: NavItemProps): JSX.Element => {
  const [showMenu, setShowMenu] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const { user, item } = props;
  const { url, icon, activeIcon, name, children } = item;

  useEffect(() => {
    if (url) {
      const routeActivated = isRouteActive(url);
      setShowMenu(routeActivated);
      setIsActive(routeActivated);
    }
  }, [url]);

  const toggleShowMenu = (event: MouseEvent): void => {
    event.preventDefault();
    setShowMenu(!showMenu);
  };

  return (
    <li
      className={classNames(styles.NavItemDropdown, {
        [styles.active]: isActive,
      })}
    >
      <button
        className={classNames(styles.button, { [styles.active]: isActive })}
        type="button"
        onClick={toggleShowMenu}
      >
        <div className={styles.info}>
          {isActive ? activeIcon : icon}
          <span>{name}</span>
        </div>
        <div className={styles.toggleIcon}>
          {showMenu ? (
            <ArrowDownIcon
              width={ICON_SIZE}
              height={ICON_SIZE}
              fill={isActive ? Colors.COLOR_BLACK : Colors.COLOR_GRAY_500}
            />
          ) : (
            <ArrowRightIcon
              width={ICON_SIZE}
              height={ICON_SIZE}
              fill={isActive ? Colors.COLOR_BLACK : Colors.COLOR_GRAY_500}
            />
          )}
        </div>
      </button>
      {children ? (
        <ul
          className={classNames(styles['dropdown-menu'], {
            [styles.show]: showMenu,
          })}
        >
          {children.map((navItem) => (
            <NavItem key={navItem.url} user={user} item={navItem} />
          ))}
        </ul>
      ) : null}
    </li>
  );
};

const NotNavItemDropdown = (props: NavItemProps): JSX.Element => {
  const [isActive, setIsActive] = useState(false);
  const { user, item } = props;
  const { url, icon, activeIcon, name, children } = item;
  useEffect(() => {
    if (url) {
      const routeActivated = isRouteActive(url);
      setIsActive(routeActivated);
    }
  }, [url]);

  return (
    <li
      className={classNames(styles.NavItemDropdown, {
        [styles.active]: isActive,
      })}
    >
      <button className={classNames(styles.button, { [styles.active]: isActive })} type="button">
        <div className={styles.info}>
          {isActive ? activeIcon : icon}
          <span>{name}</span>
        </div>
        <div className={styles.toggleIcon}>
          <ArrowDownIcon
            width={ICON_SIZE}
            height={ICON_SIZE}
            fill={isActive ? Colors.COLOR_BLACK : Colors.COLOR_GRAY_500}
          />
        </div>
      </button>
      {children ? (
        <ul
          className={classNames(styles['dropdown-menu'], {
            [styles.show]: true,
          })}
        >
          {children.map((navItem) => (
            <NavItem key={navItem.url} user={user} item={navItem} />
          ))}
        </ul>
      ) : null}
    </li>
  );
};
const NavItem = (props: NavItemProps): JSX.Element | null => {
  const { user, item } = props;

  const { admin, client, divider, title, children, hidden, noDropDown } = item;

  if (hidden) {
    return null;
  }

  if (admin && (!user || !user.isAdmin())) {
    return null;
  }

  if (client && (!user || user.isAdmin())) {
    return null;
  }

  if (divider) {
    return <NavItemDivider />;
  }

  if (title) {
    return <NavItemTitle />;
  }

  if (children && noDropDown !== true) {
    return <NavItemDropdown user={user} item={item} />;
  }
  if (noDropDown === true) {
    return <NotNavItemDropdown user={user} item={item} />;
  }
  return <NavItemLink {...item} />;
};

const Sidenav = (): JSX.Element => {
  const [user, setUser] = useState<User | null>(authServiceInstance.user);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cups, setCups] = useState<CupsInfo | null>(cupsServiceInstance.cups);
  const adminCups = useSelector((state: GlobalStoreState) => state.cups.selectedCups);

  const clientCups = useSelector((state: GlobalStoreState) => state.clientCUPS.selectedCups);
  const comunidadSelected = useSelector((state: GlobalStoreState) => state.communities.selectedCommunity);

  const [permisos, setPermisos] = useState<string[]>([]);

  const getPermisosUser = async () => {
    if (user) {
      const response = await usersService.getPermisos(user.nif);
      authServiceInstance.setUserPermisos(response);
      setPermisos(response);
    }
    return null; // Devolver null si no hay un usuario definido
  };

  useEffect(() => {
    getPermisosUser();

    const subscription: Subscription = authServiceInstance.user$.pipe(distinctUntilChanged()).subscribe(setUser);

    return (): void => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, []);

  const getCurrentLinks = (): NavItemI[] => {
    const sidenavRole = useRole();
    switch (sidenavRole) {
      case Roles.CLIENT:
        return createClientNavigationLinks(clientCups, user, permisos);

      case Roles.CUPS:
        return createTecnicCUPSNavigationLinks(adminCups, user, permisos);

      case Roles.INSTALLATION:
        return createTecnicInstallationNavigationLinks();

      case Roles.USER:
        return createTecnicUserNavigationLinks(user);

      case Roles.COMMUNITY:
        return createTecnicCommunitiesNavigationLinks(comunidadSelected);

      case Roles.PROMOTOR:
        return createPromotorNavigationLinks();

      case Roles.MATCHER:
        return createMatcherNavigationLinks();
      case Roles.ROLE_PERMISIONS:
        return createRolPermisionNavigationLinks(user);
      default:
        return [];
    }
  };

  return (
    <nav role="navigation" className={styles.root}>
      <ul className={styles.nav}>
        {getCurrentLinks().map((navItem) => (
          <NavItem key={navItem.url} user={user} item={navItem} />
        ))}
      </ul>
    </nav>
  );
};

export default Sidenav;
