import { SavingAgregateWidget, SavingWidget, SectionTitle, SharedEnergyWidget, Spinner } from 'components';
import { User } from 'types/models';
import React, { useEffect, useState } from 'react';
import { authServiceInstance, PromotorService } from 'services';
import { useDispatch } from 'react-redux';
import SharedEnergyObject from 'types/models/widgetsData/sharedEnergyObj';
import UserInfo from 'types/models/userinfo';
import I18n from 'i18n-js';
import { toast } from 'react-toastify';
import { navigate } from 'navigation';
import { fetchUser, fetchUserSuccess } from 'redux/actions/UsersActions';
import PromotorInfo from 'types/models/promotorInfo';
import PromotorDropDown from 'pages/ModeTecnic/RolInstalacion/InstallationProductionPage/PromotorDropDown';
import SavingObjectPromotor from 'types/models/widgetsData/savingObjPromotor';
import SavingAgregateCardCustom from 'components/widgets/SavingAgregateWidget/SavingAgregateWidgetCustom';
import styles from './HomePagePromotor.module.scss';

const HomePagePromotor = (props: any): JSX.Element => {
  // PROPS
  const {
    match: {
      params: { promotorID },
    },
  } = props;

  const dispatch = useDispatch();
  const [user] = useState<User | null>(authServiceInstance.user);
  const [installationClients, setIntallationClients] = useState<PromotorInfo[] | null>(null);
  const [clientSelected, setClientSelected] = useState<PromotorInfo | null>(null);
  const [sharedEnergyData, setSharedEnergyData] = useState<SharedEnergyObject | null>();
  const [loadinggenerico, setloadinggenerico] = useState<boolean>(false);
  const [loadingSharedEnergyData, setLoadingSharedEnergyData] = useState<boolean>(false);
  const [loadingSavingData, setLoadingSavingData] = useState<boolean>(false);
  const [savingData, setSavingData] = useState<SavingObjectPromotor | null>(null);

  const handleDropdownChange = async (value: PromotorInfo | null) => {
    setLoadingSavingData(true);
    setClientSelected(value);
    try {
      if (installationClients) {
        const savingDataResult = await PromotorService.getSavingData(
          promotorID,
          value === null ? installationClients : value.cau,
        );
        setSavingData(savingDataResult);
        setLoadingSavingData(false);
      }
    } catch (error) {
      toast.error(I18n.t('getEnergyDataError'));
      setLoadingSavingData(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const comunidadResult = await PromotorService.promotorListaComunidad(promotorID);
        setClientSelected(comunidadResult[0]);
        setIntallationClients(comunidadResult);

        dispatch(fetchUser());
        if (user) {
          dispatch(fetchUserSuccess(user as unknown as UserInfo));
        }
        if (comunidadResult.length !== 0) {
          const sharedEnergyResult = await PromotorService.getSharedEnergyData(promotorID, comunidadResult[0].cau);
          setSharedEnergyData(sharedEnergyResult);
          const savingDataResult = await PromotorService.getSavingData(promotorID, comunidadResult[0].cau);
          setSavingData(savingDataResult);
        }
        setloadinggenerico(true);
        setLoadingSavingData(false);
        setLoadingSharedEnergyData(false);
      } catch (error) {
        toast.error(I18n.t('getEnergyDataError'));
        setloadinggenerico(true);
        setLoadingSavingData(false);
        setLoadingSharedEnergyData(false);
      }
    };

    fetchData();
  }, [promotorID]);

  useEffect(() => {
    navigate(`/promotor/${promotorID}/home`);
  }, [promotorID]);

  // Esto es para cuando se quiera añadir el Switch de meses en el widget de
  // ahorro de Promotores
  const [fechaMesAñoArrayPositionDad, setFechaMesAñoArrayPositionDad] = useState<number>(0);

  // Con esto se inicializa la posición al último del array
  useEffect(() => {
    if (savingData?.datos?.acummulatedSavingData?.length) {
      setFechaMesAñoArrayPositionDad(savingData.datos.acummulatedSavingData.length - 1);
    }
  }, [savingData]);

  const handleDateChange = (newDate: Date, fechaMesAñoArrayPosition: number) => {
    setFechaMesAñoArrayPositionDad(fechaMesAñoArrayPosition);
  };

  return (
    <div className={styles.root}>
      {loadinggenerico ? (
        <div>
          <div className={styles.header}>
            {installationClients && installationClients?.length > 0 ? (
              <div>
                {installationClients?.length > 1 ? (
                  <div>
                    <PromotorDropDown
                      selected={clientSelected}
                      items={installationClients || []}
                      onOptionChange={handleDropdownChange}
                    />
                  </div>
                ) : (
                  <div>
                    <SectionTitle text={installationClients[0].nombre} />
                  </div>
                )}
              </div>
            ) : (
              <div>{I18n.t('UsuarioDetailPage.Promotor.noData')}</div>
            )}
          </div>
          <div className={styles.content}>
            {clientSelected === null ? null : (
              <div>
                {(() => {
                  if (loadingSharedEnergyData) {
                    return (
                      <div className={styles.shared_widget}>
                        <Spinner icon />
                      </div>
                    );
                  }
                  if (sharedEnergyData && sharedEnergyData.showWidget) {
                    return (
                      <div>
                        <div className={styles.shared_widget}>
                          <SharedEnergyWidget
                            powerInstallation={sharedEnergyData.powerInstallation}
                            totalGeneration={sharedEnergyData.totalGeneration}
                            members={sharedEnergyData.members}
                            maxHeight={styles.members_wrapperHome}
                          />
                        </div>
                      </div>
                    );
                  }
                  return null;
                })()}
              </div>
            )}

            {/* WIDGET AHORRO */}
            {(() => {
              if (loadingSavingData) {
                return (
                  <div className={styles.saving_widget}>
                    <Spinner icon />
                  </div>
                );
              }
              if (savingData && savingData.showWidget) {
                return (
                  <div className={styles.saving_widget}>
                    <SavingWidget
                      comercializado={false}
                      isClient
                      date={savingData.datos.date}
                      savingData={savingData.datos.saving}
                      costWithoutElecsumData={savingData.datos.costWithoutElecsum}
                      shrinkingData={savingData.datos.shrink}
                      selfConsumptionData={savingData.datos.selfConsumption}
                      costWithElecsumData={savingData.datos.elecsumCost}
                      acumulatedSaving={savingData.datos.acumulatedSaving}
                      startDate={savingData.datos.acummulatedSavingStart}
                      finalDate={savingData.datos.acummulatedSavingEnd}
                      onDateChange={handleDateChange}
                      maxArrayPosition={savingData?.datos.acummulatedSavingData.length}
                      fechaMesAñoArrayPositionDad={fechaMesAñoArrayPositionDad}
                      promotor
                    />
                  </div>
                );
              }
              return null;
            })()}

            {/* WIDGET AHORRO AGREGADO */}
            {(() => {
              if (loadingSavingData) {
                return (
                  <div className={styles.saving_agregate_widget}>
                    <Spinner icon />
                  </div>
                );
              }
              if (savingData && savingData.showWidget) {
                return (
                  <div className={styles.saving_agregate_widget}>
                    <SavingAgregateWidget
                      acumulatedSaving={savingData.datos.acumulatedSaving}
                      savingArray={savingData.datos.acummulatedSavingData}
                      startDate={savingData.datos.acummulatedSavingStart}
                      finalDate={savingData.datos.acummulatedSavingEnd}
                    />
                  </div>
                );
              }
              return null;
            })()}

            {(() => {
              if (loadingSavingData) {
                return (
                  <div className={styles.saving_agregate_widget}>
                    <Spinner icon />
                  </div>
                );
              }
              if (savingData) {
                return (
                  <div className={styles.saving_agregate_widget}>
                    <SavingAgregateCardCustom
                      acumulatedSaving={savingData.datos.acumulatedSaving}
                      startDate={savingData.datos.acummulatedSavingEnd}
                    />
                  </div>
                );
              }

              return null;
            })()}
          </div>
        </div>
      ) : (
        <div className={styles.spinnerGeneric}>
          <Spinner icon />
        </div>
      )}
    </div>
  );
};

export default React.memo(HomePagePromotor);
