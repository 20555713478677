import { ImageTypes } from 'types/enums';
import { Serializable } from 'types/interfaces';
import { TranslatableElement, TranslatableJSON } from 'types/interfaces/polyglot';

export interface CommunityAPII {
  id?: number;
  cau?: string;
  nombre?: string;
  descripcion?: TranslatableJSON<string>;
  domicilio?: string;
  codigoPostal?: string;
  poblacion?: string;
  provincia?: string;
  latitud?: number;
  longitud?: number;
  radioAlcance?: number;
  tipologia?: TranslatableJSON<string>;
  promocion?: TranslatableJSON<string>;
  impactoClimatico?: TranslatableJSON<string>;
  generacionInstalable?: string;
  potenciaInstalable?: string;
  fechaFinInscripciones?: string;
  estado?: string;
  tipoDeComunidad?: string;
  imagenes?: Array<{
    id: string;
    url: string;
    mostrar: ImageTypes;
  }>;
  logos?: Array<{
    id: string;
    url: string;
  }>;
  promotor?: string;

  promotores?: Array<{
    id: string;
    nombre: string;
    principal: string;
  }>;
  caracteristicas?: Array<CaracteristicasComunidad>;
  informeAutomatico?: string;
  informeInmediato?: string;
  curvaGeneracion?: string;
  curvaConsumo?: string;
  titularPoliticaPrivacidad?: string;
  consumoAnualPromedio?: number;
  limitacionNumeroInscripciones?: number;
  enviarAutorizacionAPromotor?: string;
}

export const translatableMockString: TranslatableJSON<string> = {
  es: '',
  ca: '',
};
export interface CaracteristicasComunidad {
  id: string;
  categoria: TranslatableJSON<string>;
  texto: TranslatableJSON<string>;
  valor: TranslatableJSON<string>;
}
export class CommunityModel implements Serializable<CommunityModel> {
  id: number;

  cau: string;

  nombre: string;

  descripcion: TranslatableElement<string>;

  domicilio: string;

  poblacion: string;

  provincia: string;

  codigoPostal: string;

  latitud: number;

  longitud: number;

  radioAlcance: number;

  tipologia: TranslatableElement<string>;

  promocion: TranslatableElement<string>;

  impactoClimatico: TranslatableElement<string>;

  generacionInstalable?: string;

  potenciaInstalable?: string;

  fechaFinInscripciones: string;

  estado: string;

  tipoDeComunidad: string;

  imagenes: Array<{
    id: string;
    url: string;
    mostrar: ImageTypes;
  }>;

  logos: Array<{
    id: string;
    url: string;
  }>;

  caracteristicas: Array<CaracteristicasComunidad>;

  promotor: string;

  promotores: Array<{
    id: string;
    nombre: string;
    principal: string;
  }>;

  informeAutomatico?: string;

  informeInmediato?: string;

  curvaGeneracion?: string;

  curvaConsumo?: string;

  titularPoliticaPrivacidad?: string;

  consumoAnualPromedio?: number;

  limitacionNumeroInscripciones?: number;

  enviarAutorizacionAPromotor?: string;

  deserialize(input: CommunityAPII): CommunityModel {
    if (!input) {
      return this;
    }
    this.id = input.id || 0;
    this.cau = input.cau || '';
    this.nombre = input.nombre || '';
    this.descripcion = new TranslatableElement(input.descripcion || translatableMockString);

    this.domicilio = input.domicilio || '';
    this.poblacion = input.poblacion || '';
    this.provincia = input.provincia || '';
    this.codigoPostal = input.codigoPostal || '';
    this.latitud = input.latitud || 0;
    this.longitud = input.longitud || 0;
    this.radioAlcance = input.radioAlcance || 0;

    this.tipologia = new TranslatableElement(input.tipologia || translatableMockString);
    this.promocion = new TranslatableElement(input.promocion || translatableMockString);
    this.impactoClimatico = new TranslatableElement(input.impactoClimatico || translatableMockString);

    this.generacionInstalable = input.generacionInstalable || '';
    this.potenciaInstalable = input.potenciaInstalable || '';
    this.fechaFinInscripciones = input.fechaFinInscripciones || '';
    this.estado = input.estado || '';
    this.tipoDeComunidad = input.tipoDeComunidad || '';
    this.imagenes = input.imagenes || [];
    this.logos = input.logos || [];
    this.caracteristicas = input.caracteristicas || [];
    this.promotor = input.promotor || '';
    this.promotores = input.promotores || [];
    this.informeAutomatico = input.informeAutomatico || 'no';
    this.informeInmediato = input.informeInmediato || 'no';
    this.curvaGeneracion = input.curvaGeneracion || 'no';
    this.curvaConsumo = input.curvaConsumo || 'no';
    this.titularPoliticaPrivacidad = input.titularPoliticaPrivacidad || '';
    this.consumoAnualPromedio = input.consumoAnualPromedio || 0;
    this.limitacionNumeroInscripciones = input.limitacionNumeroInscripciones || 0;
    this.enviarAutorizacionAPromotor = input.enviarAutorizacionAPromotor || 'no';

    return this;
  }

  deserializeArray(inputArray: Array<CommunityAPII>): Array<CommunityModel> {
    return inputArray.map((input) => new CommunityModel().deserialize(input));
  }

  setAttr<T>(key: string, value: T): void {
    Object.assign(this, { [key]: value });
  }
}
