/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';
import { Router, Switch, Route, RouteComponentProps, Redirect, matchPath } from 'react-router-dom';

import { RouteInterface, GuardInterface, RoutesInterface } from 'types/interfaces';

import appRoutes from 'navigation/routing/App.routing';
import { P404Component } from 'pages';
import { history, navigate, goBack } from './History';
import nav from './nav';

interface GenerateRoutesI {
  routes: Array<RouteInterface>;
  parentPath?: string;
  layout?: React.FC<RoutesInterface>;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface GenerateRouteI {
  route: RouteInterface;
}

const Navigator = (): JSX.Element => (
  <Router history={history}>
    <Switch>
      {/* <GenerateRoutes routes={appRoutes} /> */}
      {appRoutes.map((route) => (
        <GenerateRoute key={route.path} {...route} />
      ))}
    </Switch>
  </Router>
);

const generateCrumbs = (route: RouteInterface): Array<RouteInterface> => {
  let crumbs: Array<RouteInterface> = appRoutes.filter((r) => r.path !== '' && route.path.includes(r.path));
  let currentRoutes: Array<RouteInterface> | undefined = crumbs;

  while (currentRoutes && currentRoutes.length) {
    const candidate: RouteInterface = currentRoutes[currentRoutes.length - 1];
    currentRoutes = candidate.children;

    if (!currentRoutes) {
      break;
    }

    const candidates = currentRoutes.filter((r) => !r.redirect && r.path !== '/' && route.path.includes(r.path));

    if (candidates) {
      crumbs = crumbs.concat(candidates);
      currentRoutes = candidates;
    } else {
      currentRoutes = [];
    }
  }

  return crumbs;
};

const GenerateRoutes = (props: GenerateRoutesI): JSX.Element | null => {
  const { routes, parentPath, layout } = props;
  const currentPath = history.location.pathname;

  const matchFound = routes.find((route) => {
    const routePath = parentPath ? `${parentPath}${route.path}` : route.path;
    const hasMatch = matchPath(currentPath, routePath);

    return hasMatch && hasMatch.isExact;
  });

  if (!matchFound) {
    if (layout) {
      const Layout = layout;

      return <Layout component={P404Component} />;
    }

    return <P404Component />;
  }

  return (
    <>
      {routes.map((route) => {
        const newRoute: RouteInterface = {
          ...route,
          layout: route.layout || layout,
          path: parentPath ? `${parentPath}${route.path}` : route.path,
          redirect: route.redirect ? `${parentPath}${route.redirect}` : undefined,
        };

        return <GenerateRoute key={newRoute.path} {...newRoute} />;
      })}
    </>
  );
};

const GenerateRoute = (route: RouteInterface): JSX.Element | null => {
  const { exact, path, layout } = route;
  return (
    <Route
      exact={exact}
      path={path}
      render={(routeProps: RouteComponentProps): JSX.Element | null => {
        const { redirect, layout: Layout, component: Component, guards, children } = route;
        let guard = null;

        if (redirect) {
          return <Redirect to={redirect} />;
        }

        if (guards) {
          guard = guards.find((g: GuardInterface) => !g.canActivate());
        }

        if (guard) {
          const redirectUrl = guard.getRedirectUrl();

          if (redirectUrl) {
            return <Redirect to={redirectUrl} />;
          }
        }

        if (children) {
          return <GenerateRoutes layout={layout} parentPath={path} routes={children} />;
        }

        if (Layout) {
          const crumbs = generateCrumbs(route);

          return <Layout {...routeProps} component={Component} crumbs={crumbs} />;
        }

        if (Component) {
          return <Component {...routeProps} />;
        }

        return null;
      }}
    />
  );
};

const isRouteActive = (route: string, exact?: boolean): boolean => {
  const currentPath = history.location.pathname;
  const matchFound = matchPath(currentPath, { path: route });

  if (exact) {
    return matchFound !== null && matchFound.isExact;
  }

  return matchFound !== null;
};

export { history, nav, navigate, goBack, Navigator, GenerateRoutes, isRouteActive };
