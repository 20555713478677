/* eslint-disable @typescript-eslint/no-unused-vars */

import { title } from 'process';

/* eslint-disable max-len */
const translations = {
  hola: 'hola-cat',
  ca: 'ca',
  es: 'es',
  open: 'Matí',
  estandard: 'Estàndard',
  close: 'Tarda',
  solicitante: 'Sol·licitant',
  NoPermisoUsuarioPromotor: 'No tens permisos per a veure aquest component',
  'Clients List': 'Llistat de Clients',
  Client: 'Client',
  User: 'Usuari',
  noData: 'Sense dades',
  Me: 'Perfil',
  'Production guarantee (kWh/year)': 'Garantia de producció (kWh/anual)',
  'Beta distribution (KWh)': 'Beta repartiment (KWh)',
  'Beta distribution (%)': 'Beta repartiment (%)',
  "Holder's Name": 'Nom del Titular',
  Town: 'Població',
  Province: 'Província',
  'Contact name': 'Persona de contacte',
  'CONFIGURACION NO TIENE MIEMBROS': 'Configuració no té membres',
  'Lanzar informe': 'Llançar informe',
  Telephone: 'Telèfon',
  'Client saved!': 'Client guardat',
  'Data client': 'Dades client',
  'Report client': 'Informes clients',
  'Web Page': 'Pàgina Web',
  'Web page address': 'Adreça pàgina web',
  Email: 'Email',
  'Email address': 'Adreça email',
  'Enter a description': 'Introdueix una descripció',
  'Elecsum green configuration': 'Configuració Elecsum Green',
  'Elecsum green selecciona tamaño': 'Selecciona la mida',
  'News deleted successfully': 'Les noticiàs han sigut eliminades correctament',
  'caracteristicas deleted successfully': 'La característica ha sigut eliminada correctament',
  'Error deleting caracteristicas': "Error a l'esborrar la característica",
  tipoDeUsuario: `Tipus d'usuaris`,
  Headline: 'Titular',
  Categoria: 'Categoria ∗',
  CategoriaHead: 'Categoria',
  PreguntaHead: 'Pregunta',
  RespuestaHead: 'Resposta',
  IdiomaHead: 'Idioma',
  HorasHead: 'Horas',
  horaInicio: 'Hora inici',
  horaFin: 'Hora fi',
  FranjaHead: 'Franja',
  Valor: 'Valor',
  Text: 'Text ∗',
  TextHead: 'Text',

  'Headline new': 'Titular de la notícia',
  Date: 'Data',
  Description: 'Descripció',
  'News date (yyyy-mm-dd)': 'Data de la notícia (any-mes-dia)',
  'News description': 'Descripció de la notícia',
  'News description (max 1000 characters)': 'Descripció de la notícia (màx. 1.000 caràcters)',
  'Environment news': 'Notícies Medi ambient',
  'Insert new': 'Afegeix notícia',
  'Insert caracteristicas': 'Afegeix característica',
  caracateristicas: 'Característiques',
  updateSuccess: 'Dades actualitzades correctament.',

  Name: 'Nom',
  'Installation name': 'Nom de la instal·lació',
  'Number of panels': 'Nombre de panells',
  Power: 'Potència',
  'Number of inversors': "Nombre d'inversors",
  'Quantity of inversors': "Quantitat d'inversors",
  'Inversors Power': 'Potència Inversor',
  'Inversors Power [kWh]': 'Potència Inversor [kWh]',
  'Total Power': 'Potència Total',
  'Total Power [kWh]': 'Potència Total [kWh]',
  'Orientation (º)': 'Orientació (º)',
  'Tilt (º)': 'Inclinació (º)',
  'Energy ratio': 'Ràtio energètic [kWh/kWp]',
  Warranty: 'Garantia',
  'Has warranty? Yes/No': 'Té Garantia? Si/No',
  'If so, how many years?': 'En cas afirmatiu, quants anys?',
  'Service agreement?': 'Contracte de manteniment',
  'Service agreement? Yes/No': 'Té manteniment? Si/No',
  'Installation type (legalization)': "Tipus d'instal·lació (legalització)",
  'Date of reception of work': 'Data recepció obra',
  'yyyy-mm-dd (year-month-day)': 'yyyy-mm-dd (any-mes-dia)',
  'Start date production monitoring': 'Data inici seguiment producció',
  'Serial number consumption meter': 'Número sèrie comptador consum',
  'Serial number meter': 'num.Serie Comptador',
  Manufacturer: 'Fabricant',
  "Panel manufacturer's name": 'Nom del fabricant del panell',
  'Panel model name': 'Nom del model del panell',
  Model: 'Model',
  'Inverter (Wn)': 'Inversor (Wn)',
  'Inverter power': "Potència de l'inversor",
  'Degradation year 0 (%)': 'Degradació any 0 (%)',
  'Degradation year 1 (%)': 'Degradació any 1 (%)',
  'Degradation year 2 (%)': 'Degradació any 2 (%)',
  'Degradation year 10 (%)': 'Degradació any 10 (%)',
  'Degradation year 25 (%)': 'Degradació any 25 (%)',
  '(%) degradation': '(%) degradació',
  "Inverter manufacturer's name": "Nom del fabricant  de l'inversor",
  "Inverter model's name": "Nom  de l'inversor",
  'Configuracion no tine informe.': 'No iniciada',
  latitude: 'latitud',
  longitude: 'longitud',
  Latitude: 'Latitud',
  Longitude: 'Longitud',
  NOEJECUTADO: 'No executat',
  ENEJECUCION: 'En execució',
  COMPLETADO: 'Completat',
  COMPLETADOCONERRORES: 'Completat amb errors',
  'Radio for comparative monitoring with other facilities (km)':
    'Radi per fer el seguiment comparatiu amb altres instal·lacions (km)',
  'Maximum allowable deviation between facilities (%)': 'Desviació màxima permesa entre instal·lacions (%)',
  'Enter a note or reminder': 'Introdueix una nota o recordatori',
  'Installation Data': 'Dades Instal·lació',
  'Comunidad Data': 'Associar comunitat',
  'Insert comunidad': 'Afegir',
  'Panel Model': 'Model Panell',
  'Inverter Model': 'Model Inversor',
  'Location facilitie': 'Ubicació Instal·lació',
  'Facilitie radius': "Radi d'instal·lacions",
  Deviation: 'Desviació',
  Notes: 'Notes',
  Cancel: 'Cancelar',
  Save: 'Guardar',
  Rebutjar: 'Rebutjar',
  Validar: 'Validar',
  Clients: 'Clients',
  Installation: 'Instal·lació',
  installation: 'instal·lació',
  Study: 'Estudi',
  Production: 'Producció',
  'Installation saved!': 'Instal·lació guardada!',
  'Error Updating Installation.': 'Error al actualitzar instal·lació.',
  'Error saving new!': 'Error en guardar',
  'NEW saved!': 'Notícia Guardada!',
  'NEW user!': 'Usuari Guardat!',
  'caracteristica saved!': 'Característica Guardada!',
  Aceptar: 'Acceptar',
  'Oversized image!': 'Imatge molt gran!',
  'Not authorised!': 'No autoritzat!',
  'Unprivileged!': "No té privilegis d'accés!",
  'NIF not found in the system': 'NIF no trobat al sistema',
  'Fotovoltaic FV': 'Fotovoltaic FV',
  'Comunidad successfully updated': 'La comunitat ha sigut actualitzada correctament',
  'Inscrito successfully updated': "L'Inscrit ha sigut actualitzat correctament",
  'Inscrito validado': 'Validat Correctament',
  'Error updating report!': 'Error en actualitzar',
  Potencias: 'Potències',
  margenes: 'Marges',
  margenMensual: 'Marge mensual (€)',
  margenKwH: 'Marge kW (€/kWh)',
  tarifaIndexada: 'Tarifa indexada',
  Potencia: 'Potència',
  Tarifa: 'Termes energia',
  'Complete + deviation': 'Complet + Desviació',
  'Production guarantee': 'Garantia de producció',
  'Cliente actualizado correctamente': "Client actualitza't correctament",
  'Promotor actualizado correctamente': "Promotor actualitza't correctament",
  'El email de prueba ha sido enviado con éxito': '	El email de proba ha estat enviat amb èxit',

  'Images section about the company': "Imatges secció sobre l'empresa",
  'add image': 'afegeix imatge',
  'add logo': 'afegeix logo',
  'add curva': 'afegeix corba',
  'add docu': 'afegeix document',
  'add galery image': 'afegeix imatge de galeria',
  'add galery logo': 'afegeix un logo de galeria',

  'Image deleted successfully': 'Imatge eliminada',
  'Logo deleted successfully': 'Logo eliminat',
  Globales: 'Detalls de la combinació òptima  ',
  'Inscrito deleted successfully': 'Document eliminat',
  ErrorInscritoValidado: "Error al validar l'inscrit.",
  'Deleted successfully inscrit': "L'inscrit s'ha eliminat correctament",
  YaValidado: "L'inscrit ja ha sigut validat",
  lreadyValidated: 'Inscrit ja validat.',
  Unauthorized: 'Inscrit sense autorització Datadis.',
  ValidationError: "Error validant l'inscrit.",
  'Are you sure you want to delete the image?': 'Segur que vol eliminar la imatge ?',
  'Are you sure you want to delete the tipoDeContacto?': 'Segur que vol eliminar el tipus de contacte: ',
  'Are you sure you want to delete the horari?': `Segur que vol eliminar l'horari`,

  'Are you sure you want to delete the faq?': 'Segur que vol eliminar la faq : ',
  'Are you sure you want to delete the document?': 'Segur que vol eliminar el document ?',
  'Are you sure you want to delete the logo?': 'Segur que vol eliminar el logo ?',
  'Are you sure you want to delete the account?': 'Està segur que desitja eliminar el compte?',
  'Are you sure you want to delete the comunidad asociada ?': 'Està segur que desitja eliminar ',

  'Are you sure you want to delete the profile?': 'Eliminar el compte',

  'add all': 'afegeix els documents',
  'Dades factura': 'Dades factura',
  Caracteristiques: 'Característiques',
  'Dades personals': 'Dades personals',
  Inscritos: 'Inscrits',
  'Error deleting image': "S'ha produït un error en eliminar la imatge",
  'Error deleting inscrito': "S'ha produït un error en eliminar l'inscrit",
  'Error deleting logo': "S'ha produït un error en eliminar el logo",

  'Archivo adjunto': 'Arxiu adjunt',
  NO_ES_ARCHIVO_CSV: "L'arxiu no és un arxiu  CSV.",
  ARCHIVO_CSV_TIENE_FORMATO_INCORRECTO: "L'arxiu CSV té un format incorrecte.",
  ARCHIVO_CSV_DEMASIADO_GRANDE: "L'arxiu CSV és massa gran.",
  ARCHIVO_DEMASIADO_GRANDE: "L'arxiu CSV és massa gran.",
  ARCHIVO_DEMASIADO_PEQUENIO: "L'arxiu CSV és massa petit.",
  ARCHIVO_CSV_TIENE_FECHAS_DE_FORMATO_INCORRECTO: "L'arxiu CSV conté dates amb un format incorrecte.",
  ARCHIVO_TIENE_DATOS_DEMASIADO_ANTIGUAS: "L'arxiu té dades massa antigues",
  CURVA_INCORRECTA_HORAS_DUPLICADAS: 'La corba té hores duplicades.',
  CURVA_INCORRECTA_FALTAN_HORAS: 'La corba té hores que manca.',
  CURVA_INCORRECTA_NO_EMPIEZA_01_DE_ENERO_A_LA_1: "La corba no comença l'1 de gener a l'1.",
  CURVA_INCORRECTA_NO_ACABA_31_DE_DICIEMBRE_A_LAS_23: "La corba no s'acaba el 31 de desembre a les 23.",
  CURVA_INCORRECTA_TIENE_VALORES_NULOS: 'La corba té valors nuls.',
  CURVA_TIENE_VALORES_INCORRECTOS: 'La corba té valors incorrectes.',
  ARCHIVO_CSV_TIENE_SEPARADOR_INCORRECTO: "L'arxiu csv té separador incorrecte.",
  cerrada: 'Tancada',
  Tipus: 'Tipus',
  abierta: 'Admet membres',
  oculta: 'Oculta',
  enLista: 'Llista d’espera',
  completa: 'Completa',
  Validat: 'Validat',
  Estado: 'Estat:',
  Domesticos: 'Domèstics',
  Domestic: 'Domèstic',
  Particular: 'Particular',
  Empresa: 'Empresa',
  Propietario: 'Propietari',
  Autorizado: 'Autoritzats',
  Industrial: 'Industrial',
  Alquiler: 'Lloguer',
  Otros: 'Altres',
  tarifa1: '2.0TD',
  Promotor: 'Promotor',
  tarifa2: '3.0TD',
  'Peso Documento': '(5mb max PDF o JPG)',
  '(Must be 950x527px *)': '(Ha de ser de 950x527px *)',
  '(Must be 160x200px *)': '(Ha de ser de 160x200px *)',

  'We recommend 4 3 ratio and max 4MB': 'Recomanem ràtio 4:3 y màx. 4MB',
  'Header background image': 'Imatge de fons capçalera',
  'add company background image': "afegeix imatge de fons de l'empresa",
  'Must be 1440x120px *': 'Ha de ser de 1440x120px *',
  'Company Logo': 'Logo Empresa',
  'add company logo': "afegeix logo de l'empresa",
  '130x74 px *': '130x74 px *',
  'Successfully added image': 'Imatge afegida correctament',
  'Too big image': 'Imatge massa gran',
  'Not authorized': 'No autoritzat',
  'Without access privileges': "No té privilegis d'accés",
  'Nif not found on system': 'Nif no trobat al sistema',
  'Inscrit not found on system': "L'inscrit no trobat al sistema",
  '2.0TD': '2.0TD',
  '3.0TD': '3.0TD',
  'Error: Some empty field': 'Error: Hi ha camps buits',
  'Image saved!': 'Imatge guardada',
  'CSV saved!': 'Csv guardat',
  'Logo saved!': 'Logo guardat',

  'Error Uploading gallery image': 'Error en pujar la imatge',
  'Error Uploading gallery Logo': 'Error en pujar el logo',

  'Production Guarantee calculation coefficient': 'Coeficient càlcul Garantia de producció',
  'Guarantee coefficient': 'Coeficient de garantia',
  ErrorInscritos: 'Document massa gran',
  'Inscrito saved!': 'Document desat',
  Noinscritos: 'No hi ha cap document',
  mensajeModal: 'Està segur que vol validar a ',
  mensajeModalTarifa: 'Està segur que vol validar la sol·licitud de ',
  mensajeModalBody: "Passarà a la llista d'usuaris inscrits a la comunitat com validat",
  mensajeModalBodyTarifa: "S'actualitzará la tarifa d'aquest client",
  spacio: ' ',
  Guardar: 'Desa',
  Confirmar: 'Confirmar',
  Month: 'Mes',
  'Project Estimation (kWh/month)': 'Estimació projecte (kWh/mes)',
  'Production guarantee (kWh/month)': 'Garantia producció (kWh/mes)',
  January: 'Gener',
  February: 'Febrer',
  March: 'Març',
  April: 'Abril',
  May: 'Maig',
  June: 'Juny',
  July: 'Juliol',
  August: 'Agost',
  September: 'Setembre',
  October: 'Octubre',
  November: 'Novembre',
  December: 'Desembre',
  enero: 'Gener',
  febrero: 'Febrer',
  marzo: 'Març',
  abril: 'Abril',
  mayo: 'Maig',
  junio: 'Juny',
  julio: 'Juliol',
  agosto: 'Agost',
  septiembre: 'Setembre',
  octubre: 'Octubre',
  noviembre: 'Novembre',
  diciembre: 'Desembre',
  gener: 'Gener',
  febrer: 'Febrer',
  març: 'Març',
  maig: 'Maig',
  juny: 'Juny',
  juliol: 'Juliol',
  agost: 'Agost',
  septembre: 'Setembre',
  novembre: 'Novembre',
  desembre: 'Desembre',
  'Generation estimate in watt hours': 'Estimació de generació en vats hora',
  Year: 'Any',
  'Data studio saved!': "Dades d'estudi guardades!",
  'Error Updating Data studio.': "Error a l'actualitzar les dades",
  installationNotSelected: "No s'ha seleccionat cap instal·lació",
  home: 'Home',
  charts: 'Gràfiques',
  accounting: 'Comptabilitat',
  sustainability: 'Sostenibilitat',
  'renovable-stamp': 'Segell renovable',
  'new contract': 'Contracte nou',
  Image: 'Imatge',
  Logo: 'Logo',

  Weight: 'Pes',
  Actions: 'Accions',
  Documentos: 'Documents',
  Delete: 'Esborrar',
  Total: 'Total',
  generation: 'Generació',
  shrinking: 'Excedent',
  network: 'Xarxa',
  selfConsumption: 'Autoconsum',
  consumption: 'Consum',
  energy: 'Energia',
  Tarifes: 'Tarifes',
  saving: 'Estalvi',
  costWithoutElecsum: 'Cost sense Elecsum',
  costWithElecsum: 'Cost amb Elecsum',
  selfConsumptionFen: 'Autoconsumida',
  amortization: 'Amortització',
  getEnergyDataError: "Error en carregar les dades d'energia",
  getSummaryDataError: 'Error en carregar les dades del resum de contracte',
  getSharedEnergyDataError: 'Error en carregar les dades de generació repartida',
  'current month': 'mes en curs',
  Energy: 'Energia',
  'Production guarantee evolution': 'Evolució Garantia de producció',
  'production guarantee': 'Garantia de producció',
  'guarantee percentage achieved': 'Percentatge de garantia aconseguit',
  'Comercial name': 'Nom comercial',
  paltfix: 'paltfix',
  platflexa2: 'platflexa2',
  platflexa3: 'platflexa3',
  getSavingDataError: "Error en carregar les dades d'estalvi",
  getAmortizationDataError: "Error en carregar les dades d'amortització",
  in: 'En',
  monthYouAmortized: 'mesos has amortitzat',
  ofTheInstalation: 'de la instal·lació',
  'see web': 'veure web',
  Consumption: 'Consum',
  'Renewable energy production': 'Producció energia renovable',
  'Renewable energy consumption': 'Consum energia renovable',
  'current year': 'any en curs',
  'accumulated total': 'total acumulada',
  'contact us': 'contacta amb nosaltres',
  'If you want more information about': 'Si vols més informació sobre',
  'photovoltaic installations': 'instal·lacions fotovoltaiques',
  '"Elecsum Green certifies the generation and / or 100% renewable energy consumption, See the details below"':
    '“Elecsum Green certifica la generació i/o consum d’energia 100% renovable. Consulta’n més avall els detalls.”',
  'Updated information a': 'Informació actualitzada a ',
  'certifies that': ' certifica que ',
  'is a customer of': " és client d' ",
  'as of today': " a data d'avui ",
  'ELECTRA CALDENSE ENERGIA S L is provided exclusively with 100% renovable energy':
    'ELECTRA CALDENSE ENERGIA S.A. es proveeix exclusivament d’energia 100% renovable.',
  'See CNMC certificate': 'Veure certificat de la CNMC',
  about: 'sobre ',
  'Our commitment to the Environment': 'El nostre compromís amb el medi ambient',
  noEmmited: 'no emès',
  trees: 'arbres',
  planted: 'plantats',
  viewMore: 'Veure més',
  myContracts: 'Els meus contractes',
  invoiceTotal: 'Total factura',
  invoice: 'Factura',
  invoices: 'Factures',
  invoice_number: 'Número de factura',
  invoice_date: 'Data factura',
  errorGettingInvoiceInfo: 'Error sol·licitant factura',
  errorDownloadInfo: 'Error en baixa la factura',
  period: 'Període',
  documents: 'Documents',
  egreen: 'Elecsum Green',
  projectEstimation: 'Estimació del projecte',
  study: 'Estudi',
  Global: 'Global',
  activeClient: 'Client actiu',
  year: 'any',
  productionGuarantee: 'Garantia de Producció',
  actualYear: 'Any en curs',
  goalAccomplished: "d'objectiu de producció aconseguit!",
  'Are you sure you want to delete the new?': 'Estàs segur que vols esborrar la notícia?',
  'Are you sure you want to delete the característica?': 'Estàs segur que vols esborrar la característica?',

  'New deleted successfully': 'Notícia esborrada correctament',
  'Error deleting new': "Error a l'esborrar la notícia",
  'Client successfully updated': 'Client actualitzat amb èxit',
  Cost: 'Cost',
  SelecionaTarifa: 'Seleccioni la tarifa indicada',
  SelecionaImagen: 'Seleccioni que tipus és la imatge:',
  Imatge: 'Imatge',
  SelecionaComunidad: 'Selecciona la comunitat',

  'Installation cost': 'Cost de la instal·lació',
  noDataObtained: 'Sense dades',
  downloadInvoice: 'Descarregar factura',
  download: 'Descarregar',
  Comunitat: 'Comunitat',
  'La meva': 'La meva',
  daily: 'Diari',
  monthly: 'Mensual',
  annual: 'Anual',
  historical: 'Històric',
  surplus: 'Excedent',
  differenceInst: 'Diferència Instal·lacions radi',
  diffGuarantee: 'Diferència Garantia de producció',
  estimateProject: 'Valor estimat de projecte',
  differenceEstimate: 'Diferencia valor estimat projecte',
  JAN: 'GEN',
  FEB: 'FEB',
  MAR: 'MAR',
  APR: 'ABR',
  MAY: 'MAI',
  JUN: 'JUN',
  JUL: 'JUL',
  AUG: 'AGO',
  SEP: 'SEP',
  OCT: 'OCT',
  NOV: 'NOV',
  DEC: 'DEC',
  'My Profile': 'El meu perfil',
  'My Info': 'Les Meves dades',
  'Change Password': 'Canvi de contrasenya',
  'Delete Cuenta': "Eliminar l'usuari",
  'Email Cuenta': 'Actualitzar',
  Surname: 'Cognoms',
  NIF: 'NIF / DNI',
  Password: 'Contrasenya',
  'Actual Password': 'Contrasenya Actual',
  'New Password': 'Nova contrasenya',
  'Repeat New Password': 'Repeteix la nova contrasenya',
  'min 8 characters': 'mín. 8 caràcters',
  'Password Changed': 'Contrasenya canviada correctament',
  'Delete Account': "L'usuari ha sigut eliminat correctament",
  errorDeleteAccount: "S'ha produït un error en eliminar l'usuari",
  errorNewPasswordNotValid: 'Error, contrasenya no vàlida!',
  description: 'Descripció',
  date: 'Data',
  hour: 'Hora',
  status: 'Estat',
  contribution: 'Contribució',
  instalations: 'instal·lacions',
  parisAgreement2015: "Paquet de l'energia neta de la UE",
  whatAreParisAgreementObjectives: 'Quins són els objectius del Clean Energy package a 2030?​',
  UEObjective: 'Objectiu de reducció CO2 Unió Europea 2030',
  ESObjective: 'Objectiu de reducció CO2 Espanya 2030',
  milionTones: 'milions tones CO2',
  emisions: 'Emissions',
  noEmmitedYear: 'no emeses / any',
  myContribution: 'La meva contribució',
  alerts: 'Alertes',
  alerts_pending: 'Alertes pendents',
  spain: 'ESPANYA',
  from: 'del',
  to: 'a',
  Data: 'Dades',
  'Study data': 'Estudi',
  Home: 'Home',
  activeCups: 'Adreça',
  Charts: 'Gràfiques',
  Sustainability: 'Sostenibilitat',
  Logout: 'Tanca Sessió',
  'Sign in': 'Inicia Sessió',
  'Data  Client': 'Dades de client',
  day: 'dia',
  month: 'mes',
  historic: 'històric',
  search: 'Cerca instal·lació, client o CUPS',
  errorModifyingAlert: "Error al modificar l'alerta, torna a provar-ho més tard",
  errorGetAlertsData: "Error al carregar la informació d'alertes, torni ha provar-ho més tard",
  noAlertsMessage: 'No hi ha alertes per mostrar',
  pending: 'Pendent',
  doing: 'En curs',
  done: 'Resolta',
  alertsList: "Llistat d'alertes",
  guaranteeEvolution: 'Evolució garantia',
  guarantees: 'Garanteix',
  'Error Updating Installation': 'Error al actualitzar la instal·lació',
  Others: 'Altres',
  'Instalation titularity percentage': 'Percentatge titularitat instal·lació',
  'Coste acceso': "Cost d'accés",
  Address: 'Adreça',
  emmited: 'emeses',
  yes: 'Si',
  no: 'No',
  desde: 'Des de',
  query: 'Consulta',
  getElecsumGreenError: 'Error al carregar el identificador Elecsum Green',
  thisMonthSavingWithElecsum: 'Aquest mes, amb Elecsum hauries estalviat',
  getPotencialSaving: "Aconsegueix l'estalvi potencial que et mereixes",
  accumulatedSavings: 'Estalvi acumulat',
  emmitedHabYear: 'emeses per habitant / any',
  checkAndShareElecsumGreen: 'Consulta aquí el teu segell verd i comparteix-lo',
  'Set New Password': 'Configura la nova contrasenya',
  newPasswordAtLeast8: 'La nova contrasenya ha de tenir com a mínim 8 caràcters',
  invalidToken: 'Token invàlid o caducat',
  errorRecoverPassword: 'Error al intentar canviar contrasenya',
  contactUs: 'Contacta amb nosaltres',
  totalAccumulated: 'Total acumulat',
  installationPowerTotal: 'Potència total instal·lació',
  sharedEnergy: 'Generació repartida',
  loginBadCredentials: "Error d'autenticació, credencials incorrectes.",
  energiaeolica: 'Eòlica',
  documents_no_documents: "No s'han trobat documents per a aquest CUPS.",
  CIM: {
    DashboardPage: {
      errors: {
        contractSummaryError: 'Error obtenint dades Resum de Contracte',
        maximetersError: 'Error obtenint les dades de Maxímetres.',
      },
    },
    InvoiceSimulationPage: {
      Commons: {
        day: 'dia',
        days: 'dies',
        simulationInterval: 'Interval de simulació',
        dateInterval: 'Interval de dates',
        period: 'Període',
        description: 'Descripció',
        total: 'Total',
        simulate: 'Simular',
        reset: 'Reiniciar',
      },
      Powers: {
        p1: 'Potència 1',
        p2: 'Potència 2',
        p3: 'Potència 3',
        p4: 'Potència 4',
        p5: 'Potència 5',
        p6: 'Potència 6',
      },
      ResumenSection: {
        title: 'Total factura',
        base: 'Base',
        iva: 'Iva',
        total: 'Import total',
      },
      DatosContratacionSection: {
        title: 'Dades de contractació',
        tarifa: "Tarifa d'accés",
        potencia: 'Potència contractada',
        titular: 'Titular',
        domicilio: 'Domicili del suministre',
      },
      Lecturas: {
        title: 'Lectures',
        active: 'Activa',
        activeTotal: 'Total activa',
        reactive: 'Reactiva',
        reactiveTotal: 'Total reactiva',
        maximeter: 'Maxímetre',
        consume: 'Consum',
      },
      Consumos: {
        title: 'Consums',
        powerTerm: 'Terme de potència',
        powerTotal: 'Total potència',
        energyTerm: "Terme d'energia",
        energyTotal: 'Total energia',
        reactiveTerm: 'Energia reactiva',
        reactiveTotal: 'Total reactiva',
        powerExcessTerm: 'Excés de potència',
        powerExcessTotal: 'Total excés',
        electricityTax: "Impost sobre l'electricitat",
        hardwareItems: 'Equips de mesura',
        excess: 'Excedents',
        excessTotal: 'Total excedents',
      },
      Errores: 'No disposem de dades per a realitzar el càlcul sol·licitat.',
    },
    CIMDeviceInfoPage: {
      title: 'Equips de mesura',
      medida: 'Mesura',
      numeroDeContador: 'Número de comptador',
      numeroDeModem: 'Número de mòdem',
      propiedad: 'Propietat',
      telegestionado: 'Telegestionat',
      tipoDeMedicion: 'Tipus de mesura',
      errorFetchingData: 'Error obtenint les dades dels equips de mesura.',
    },
    MeasurementRegistersPage: {
      intervalLabel: 'Interval de dates',
      typeLabel: 'Tipus de gràfica',
      agregated: 'Acumulat',
      maximeter: 'Maxímetres',
      grouping: 'Agrupació per',
      all: 'Tot',
      hour: 'Hora',
      day: 'Dia',
      week: 'Setmana',
      month: 'Mes',
      active: 'Activa',
      hired: 'Contractada',
      Activa: 'Activa',
      Capacitiva: 'Capacitiva',
      Inductiva: 'Inductiva',
      Exportada: 'Exportada',
      Neta: 'Neta',
      Excedent: 'Excedent',
    },
    ClosuresPage: {
      pickerLabel: 'Interval de tancament',
      noClosures: 'No hi ha tancaments disponibles.',
      errorFetching: 'Error obtenint les dades de tancaments.',
    },
    DataExportPage: {
      picker_label: 'Exportació de dades',
      errorFetching: "Error obtenint les dades d'exportació",
    },
    PowerOptimizationPage: {
      errorFetching: "Error obtenint les dades d'optimització",
      interval: "Interval d'optimització",
      loadingTitle: 'Consultant dades de teledetecció del mòdem associat.',
      loadingSubtitle: 'Aquest procés pot durar uns instants...',
      noData: 'No disposem de dades per a realitzar el càlcul sol·licitat.',
      SummarySection: {
        title: 'Resum de totes les factures',
        totalEnergy: 'Total Energia',
        totalPower: 'Total Potència',
        totalReactive: 'Total Reactiva',
        total: 'Total',
      },
      OptimizationSection: {
        title: 'Optimizació de les potències',
        period: 'Període',
        optimal: 'Potència òptima',
        actual: 'Potència actual',
        no_data: "No hi ha dades d'optimització disponibles.",
      },
      SavingSection: {
        title: 'Estalvi',
        savingLabelImport: 'Aconseguint un estalvi de',
        savingLabelPercent: 'Aconseguint un estalvi del',
        iva: 'IVA inclòs',
      },
    },
  },
  MaximetersWidget: {
    title: 'Darrers maxímetres',
  },
  ComercializationSummaryWidget: {
    title: 'Resum comercialització',
    tarifa: 'Tarifa',
    year: 'Any',
    tPotencia: 'Termes potència',
    tEnergia: 'Termes energia',
  },
  ContractResumCard: {
    title: 'Resum de contracte ATR',
    solar: 'Fotovoltaica',
    motor: 'Motor',
    eolica: 'Eòlica',
    bateria: 'Bateria',
    potencia: 'Potència contractada',
    cliente: 'Codi client',
    tarifa: 'Tarifa',
    tarifaComercial: 'Tarifa comercialitzadora',
    porcentajeElectricidadBonificacion: 'Percentatge bonificació',
    porcentajeExcencionImpuesto: 'Percentatge exempció',
    instalaciones: "Tipus d'instalació",
  },
  PowerOptimizationWidget: {
    title: 'Optimització de potència',
    label: 'Optimització',
  },
  MensualConsumptionWidget: {
    title: 'Registre lectures de comptador',
    titleTarifa: 'Registre consums',
  },
  InvoiceCard: {
    status: {
      pendiente: 'Pendent',
      pagada: 'Pagada',
      impagada: 'Impagada',
    },
  },
  RegisteredUsersWidget: {
    title: 'Usuaris',
    label: 'Usuaris registrats',
    link: 'Veure més',
  },
  UsersPage: {
    goDashboard: 'Tornar a dashboard',
    tableTitle: "Llistat d'Usuaris",
    csvLabel: 'Exportar en CSV',
    gestionRols: 'Gestionar Rols',
    userLabel: 'Afegir usuari',
    tableHeaders: {
      nif: 'NIF',
      alias: 'Nom i Cognoms',
      email: 'Email',
      apps: 'Sistema App',
      tipoDeUsuario: `Tipus d'usuaris`,
    },
  },
  DashboardPage: {
    alerts_fetching_error: "Error en carregar la informació d'alertes, torni a provar-ho més tard",
    users_fetching_error: "Error en carregar la informació d'usuaris, torni a provar-ho més tard.",
    users_no_data: "No hi ha dades d'usuaris",
    nodata_grafic: 'No hi ha dades',

    cups_fetching_error: 'Error en carregar la informació de CUPS, torni a provar-ho més tard.',
    cups_no_data: 'No hi ha dades de CUPS',
    comunity_fetching_error: 'Error en carregar la informació de les comunitats, torni a provar-ho més tard.',
    solicitud_fetching_error:
      "Error en carregar la informació de les sol·licituds d'actualització de tarifa, torni a provar-ho més tard.",
    comunity_no_data: 'No hi ha dades de cap comunitat',

    RegisteredCUPSWidget: {
      title: 'CUPS',
      p1: 'CUPS registrats',
    },
    RegisteredInstallationWidget: {
      title: 'Instal·lacions',
      p1: 'Instal·lacions',
    },
    ComunidadesDashboardWidget: {
      title: 'Comunitats',
      more: 'Veure més',
      comunities: 'Comunitats',
      pending: 'Inscrits pendents de validar',
      viewmore: 'Veure més',
    },
    TarifasDashboardWidget: {
      title: "Sol·licituds d'actualització de tarifes pendents de validar",
      more: 'Veure més',
      pending: "Sol·licituds d'actualització de tarifes  pendents de validar",
      viewmore: 'Veure més',
    },
    MatcherDashboardWidget: {
      title: 'Matcher',
      more: 'Veure més',
      pending: 'Matcher',
      viewmore: 'Veure més',
    },
  },
  DateIntervalPicker: {
    start_date: 'Data inicial',
    end_date: 'Data final',
  },
  AlertsPage: {
    goDashboard: 'Tornar a dashboard',
  },
  Sidenav: {
    Client: {
      Home: 'Home',
      Charts: 'Gràfiques',
      Invoices: 'Factures',
      Documents: 'Documents',
      Sustainability: 'Sostenibilitat',
    },
    Tecnic: {
      User_Data: 'Dades',
      User_CUPS: 'CUPS',
      User_Egreen: 'Elecsum Green',
      CUPS_Detail: 'Detall',
      CUPS_Dashboard: 'Dashboard',
      Installation_Clients: 'Clients',
      Installation_Installation: 'Instal·lació',
      Installation_Study: 'Estudi',
      Installation_Production: 'Producció',
      Communities_Detail: 'Detall',
      Communities_Members: 'Membres',
      Communities_Enrolled: 'Inscrits',
      Communities_Analytics: 'Anàlisis',
      Communities_Customization: 'Personalització',
      Communities_Promotores: 'Promotors',
      Factura: 'Factura',
      Matcher_Perfil: 'Perfils de consum',
      Matcher: 'Matcher',
      Preferencias: 'Preferències',
      RolesPermisions: 'Rols',
      CupsAsociados: 'Cups associats',
    },
    CIM: {
      cim: 'CIM',
      dashboard: 'Dashboard',
      registers: 'Registres de mesura',
      simulation: 'Simulació de factura',
      optimization: 'Optimització de potència',
      devices: 'Equips de mesura',
      closures: 'Tancament',
      export: 'Exportació de dades',
    },
    UpdateTarifa: {
      mas: 'Més',
      updateTarifa: 'Actualitzar tarifa',
    },
    cupsAutorizados: 'CUPS autoritzats',
  },
  UpdateTarifaPage: {
    cups: 'CUPS',
    mas: 'més',
    updateTarifa: 'Actualitzar tarifa',
    descripcion: 'Puja la teva última factura i introdueix les següents dades:',
    domestico: 'Domèstic',
    tipoSolicitante: 'Tipus de Sol·licitant',
    industrial: 'Industrial',
    indexado: 'Indexat',
    noIndexado: 'No indexat',
    regulada: 'Tarifa indexada (PVPC)',
    añadeImagen: "Adjunta la teva darrera factura d'energia disponible",
    margeMensual: 'Marge mensual (€/mes)',
    margeKw: 'Marge energia (€/kWh)',
    compensacion: "Compensació d'excedents",
    compensacionImporte: 'Import',
    preuTerme: "Termes d'energia (en €/kWh)",
    margenesTecnico: 'Marges mensual/kWh',
    margenes: 'Marges de benefici',
    consumPunta: 'Preu punta',
    consumPla: 'Preu pla',
    consumVall: 'Preu vall',
    p1: 'Preu període 1',
    p2: 'Preu període 2',
    p3: 'Preu període 3',
    p4: 'Preu període 4',
    p5: 'Preu període 5',
    p6: 'Preu període 6',
    dades: "Sol·licitud d'actualització de tarifa",
    toastCorrecto: "Sol·licitud d'actualització de tarifa enviada correctament",
    toastCorrectoGuardada: "Sol·licitud d'actualització de tarifa guardada correctament",
    toastFallido: "Error en pujar la sol·licitud d'actualització de tarifa",
    enviar: 'Enviar',
    enviarSeguro: 'Segur que vol enviar la sol·licitud per actualitzar la seva tarifa ?',
    cupInfo:
      'Introdueix el CUP amb el que vols entrenar a la comunitat solar, verificar-lo ens ajudarà a proporcionar-te unes dades més exactes:',
  },
  HeaderSearchInput: {
    inputPlaceholder: 'Cerca per instal·lació, CUPS, usuari',
    inputPlaceholderCupsAsociado: 'Cerca per CUPS',
    inputPlaceholderMatcher: 'Cerca per instal·lació',
    filterInstallation: 'Instal·lacions',
    filterCUPS: 'CUPS',
    filterUser: 'Usuario',
    filterCommunity: 'Comunitat',
  },
  UsuarioDetailPage: {
    UserData: {
      title: 'Dades usuari',
      titleSmtp: 'Dades email SMTP',
      labels: {
        name: 'Nom',
        surname: 'Cognoms',
        email: 'Email',
        nif: 'NIF',
      },
      promotorLabels: {
        smtpHost: 'Host',
        smtpPort: 'Port',
        username: 'Email',
        password: 'Contrasenya',
        useTls: 'TLS',
        useSsl: 'SSL',
        telefonoDeContacto: 'Telèfon de contacte',
      },
    },
    DevicesData: {
      title: 'Dispositius registrats',
      noData: "L'usuari no té dispositius registrats",
      headers: {
        devices: 'Dispositius',
        system: 'Sistema App',
      },
    },
    Promotor: {
      title: 'Comunitats associades',
      titleComunidadPromotor: 'Promotors associats',
      noDataComunidadPromotor: 'Aquesta comunitats no té promotors associats',
      noData: 'Aquest promotor no té comunitats associades',
      errorContra: 'La contrasenya no coincideix',
      errorEmail: 'El correu és invàlid',
      headers: {
        devices: 'Comunitat',
        Promotors: 'Promotors',
        principal: 'Principal',
      },
    },
  },
  UsuarioCUPSPage: {
    title: 'Llistat de CUPS',
    noData: "L'usuari no té CUPS vinculats.",
    headers: {
      cups: 'CUPS',
      address: 'Adreça',
      city: 'Població',
    },
    viewInstallation: 'Veure instal·lació',
  },
  SelectUserType: {
    label: 'Tipus de client',
    basic: 'Bàsic',
    premium: 'Premium',
  },
  CUPSDetailPage: {
    viewInstallation: 'Veure instal·lació associada',
    viewUser: 'Veure usuari associat',
    labels: {
      monthly: 'Mensual',
      yearly: 'Anual',
    },
  },
  SignUpPage: {
    steps: {
      user: 'Usuari',
      email: 'Email',
      password: 'Contrasenya',
    },
    success: {
      title: 'Usuari registrat correctament.',
      subtitle: 'Ja pots iniciar sessió amb el teu nou usuari.',
      login: 'Iniciar sessió',
    },
    form: {
      nifLabel: 'NIF / ID',
      nifPlaceholder: 'NIF / ID titular del CUPS',
      cupsLabel: 'CUPS',
      cupsPlaceholder: 'Identificador CUPS',
      cupsHelper: {
        title: 'No trobes el teu CUPS?',
        p1: "El número CUPS, és el codi alfanumèric que identifica cada casa o negoci, tant per a la xarxa d'electricitat com de la xarxa de gas natural.",
        p2: "Està format per dues lletres inicials que indiquen el codi del país, en el cas d'Espanya: ES, seguit de 20 o 22 caràcters.",
      },
      passwordLabel: 'Contrasenya',
      passwordPlaceholder: "Contrasenya d'accés",
      passwordRepeatLabel: 'Repetir contrasenya',
      passwordRepeatPlaceholder: "Repetir contrasenya d'accés'",
      continue: 'Continuar',
      back: 'Enrere',
      finish: 'Finalitzar registre',
    },
    errors: {
      submitEmail: 'Email ja registrat al sistema',
      submitNIF: 'NIF ja registrat al sistema',
      cupsRequired: 'Camp CUPS obligatori.',
      nifRequired: 'Camp NIF obligatori.',
      cupsNIFNoExist: 'El NIF o CUPS no existeix/en al sistema.',
      emailRequired: 'Camp email obligatori.',
      emailBad: "El campo no té un format d'email.",
      emailNotSame: 'Les direccions introduïdes no coincideixen.',
      passwordLength: 'Mínim 8 caràcters',
      passwordRequired: 'Camp contrasenya obligatori.',
      passwordNotSame: 'Les contrasenyes introduïdes no coincideixen.',
    },
  },
  LoginPage: {
    form: {
      idLabel: 'NIF / ID',
      idPlaceholder: 'NIF / ID',
      passwordLabel: 'Contrasenya',
      passwordPlaceholder: "Contrasenya d'accés",
      login: 'Inicia sessió',
      forgotPassword: 'Has oblidat la contrasenya?',
      register: "Registra't",
      notRegistered: 'No tens un compte?',
    },
    errors: {
      badCredentials: "Error d'autenticació, credencials incorrectes.",
      idRequired: 'Camp identificador obligatori.',
      passwordRequired: 'Camp contrasenya obligatori.',
    },
  },
  NotFound404Page: {
    title: 'Error 404: pàgina sol·licitada no trobada',
    match: 'Cap coincidència per a:',
    home: 'Tornar a la pàgina principal',
  },
  ForgotPasswordPage: {
    helper: 'Introdueix l’email associat al teu compte per recuperar la contrasenya.',
    helperNif: 'Introdueix el NIF associat al teu compte per recuperar la contrasenya.',

    recover: 'Recupera la contrasenya',
    successTitle: 'Sol·licitud enviada',
    successMsg: "Si estàs registrat al sistema, se t'enviarà un correu per recuperar la contrasenya.",
    goBack: 'Tornar',
  },
  CUPSListPage: {
    error: {
      noData: "No s'han trobat cap resultat.",
    },
    filters: {
      title: 'Llistat de CUPS',
      searchPlaceholder: 'Filtrar CUPS per text',
      autoconsumo: 'Autoconsum',
      mantenimiento: 'Manteniment',
      garantia: 'Garantia',
      industrial: 'Industrial',
      years: 'Anys de garantia',
      allYears: 'Tots',
    },
    paginacion: {
      page: 'Pàgina',
    },
    headers: {
      cups: 'CUPS',
      name: 'Nom / Raó social',
      domicilio: 'Domicili',
    },
    row: {
      verDetalle: 'Veure',
      verInstalacion: 'Veure instal·lació',
      autoconsumo: 'Autoconsum',
      mantenimiento: 'Manteniment',
      garantia: 'Garantia',
      yearsGarantia: 'Anys de garantia',
    },
  },
  InstallationListPage: {
    error: {
      noData: "No s'han trobat cap resultat.",
    },
    title: "Llistat d'Instal·lació",
    filters: {
      title: 'Llistat de CUPS',
      searchPlaceholder: 'Filtra instal·lacions per text',
      autoconsumo: 'Autoconsum',
      mantenimiento: 'Manteniment',
      garantia: 'Garantia',
      industrial: 'Industrial',
      years: 'Anys de garantia',
      allYears: 'Tots',
    },
    paginacion: {
      page: 'Pàgina',
    },
    headers: {
      idELECSUM: 'idELECSUM',
      CAU: 'CAU',
      CILL: 'CILL',
      NombreInstalacion: 'Nom instal·lació',
      Nombre: 'Nom i Cognoms',
      Domicilio: 'Domicili',
    },
    row: {
      verDetalle: 'Veure',
      verInstalacion: 'Veure instal·lació',
      autoconsumo: 'Autoconsum',
      mantenimiento: 'Manteniment',
      garantia: 'Garantia',
      yearsGarantia: 'Anys de garantia',
    },
  },
  ComunidadesListPage: {
    title: 'Llistat de comunitats',
    searchPlaceholder: 'Buscar comunidades',
    Inscrits: 'Inscrits',
    Places: 'Places disponibles',
    headers: {
      name: 'Nom',
      location: 'Ubicació',
      inscription: "Termini d'inscripció",
      status: 'Estat',
      detail: 'Veure',
    },
    page: 'Pàgina',
    estado: {
      abierta: 'Admet membres',
      enLista: 'Llista d’espera',
      oculta: 'Oculta',
      completa: 'Completa',
      cerrada: 'Tancada',
      titulo: 'Estat: ',
    },
    tipoDeComunidad: {
      estandard: 'Estàndard',
      otc: 'OTC',

      titulo: 'Tipus de Comunitat: ',
      checkSignaturitPromotor: 'Enviem una còpia del document signat a Signaturit al promotor?',
    },
  },
  ComunitiesEnrolledListPage: {
    title: "Llistat d'inscrits",
    searchPlaceholder: 'Buscar',
    headers: {
      name: 'Nom inscrit',
      community: 'Comunitat',
      status: 'Estat',
      direccion: 'Direcció',
      telefono: 'Telèfon',
      notResult: 'No te cap inscrits',
      CUPS: 'Cups',
      Data: `Inscripció`,
      nombreComunidad: 'Comunitat',
    },
    tagPending: 'Pendiente',
    validate: 'Validar',
    page: 'Pàgina',
    notResult: 'No hi ha cap inscrit associat',
    detalles: 'Veure',
    PendientaValidar: 'Pendent de validar',
  },
  TarifasListPage: {
    title: "Sol·licitud d'actualització de tarifa",
    datosTarifa: 'Dades tarifa',
    datosSolicitud: 'Dades sol·licitud',
    searchPlaceholder: 'Buscar',
    headers: {
      name: 'Nom',
      nif: 'Nif',
      notResult: 'No hi ha cap sol·licitud de canvi de tarifa',
      CUPS: 'Cups',
      comunidad: 'Comunitat',
      errorComunidad: 'Error en obtenir el nom de la comunidat',
      fecha: `Data`,
    },
    tagPending: 'Pendiente',
    validate: 'Validar',
    page: 'Pàgina',
    notResult: 'No hi ha cap sol·licitud de canvi de tarifa',
    detalles: 'Veure',
    PendientaValidar: 'Pendent de validar',
  },
  MatcherListPage: {
    title: 'Configuracions',
    datosTarifa: 'Dades tarifa',
    datosSolicitud: 'Dades sol·licitud',
    searchPlaceholder: 'Buscar',
    add: 'Afegir configuració',
    addMember: 'Afegir un membre',
    loadMember: 'Carregar membres des de csv',
    updateMember: 'Actualitza un membre',
    addPerfilConsumo: 'Afegir la corba de consum en format .csv',
    loadPerfilConsumo: "Afegir els membres a partir d'un csv",
    linea: 'línia',
    headers: {
      name: 'Nom',
      nif: 'Nif',
      notResult: 'No hi ha cap sol·licitud de canvi de tarifa',
      CUPS: 'Cups',
      report: 'Informe',
      comunidad: 'Comunitat',
      numberMember: 'Membres',
      errorComunidad: 'Error en obtenir el nom de la comunidat',
      fecha: `Data`,
    },
    addMatcher: {
      description: 'Afegir una configuració al Matcher',
      generacio: 'Generació solar',
      miembros: 'Llista de membres',
      selectInscritos: 'Selecciona inscrits',
      deseaInscrito: "Desitja pujar les dades d'un inscrit?",
      generacioTitle: 'Puja la corba de generació',
      generacioDesc: 'Puja la corba de generació solar',
      generacioDescExist: 'Desitges actualitzar la corba de generación solar?',
      exportGeneracioDescExist: 'Desitja descarregar la corba de generación solar?',
      generacioSolar: 'Generación solar',
      subirCurva: 'Puja la corba de consum',
      comportamiento: 'Configuració comportament',
      betamax: 'βmax',
      betamin: 'βmin',
      betastep: 'βstep',
      maxMiembros: 'Maximitzar',
      minMiembros: 'Minimitzar',
      consumo: 'Consum',
      comunidad: 'Selecciona comunitat',
      selectPerfil: 'Selecciona perfil de consum',
      name: 'Nom',
      apellidos: 'Cognoms',
      nif: 'Nif',
      cups: 'CUPS',
      seleccionado: 'Seleccionat',
      vip: 'Vip',
      preferent: 'Preferent',
      perfilConsumo: 'Perfil de consum',
    },
    informe: {
      estadoInforme: "Estat de l'informe: ",
      tarda: "Temps d'espera aproximat: fins a 5 minuts",
      miembroExcluido: 'Membres exclosos',
      miembroAceptado: 'Membres acceptats',
      listaCombinacion: 'Llista de combinacions',
      lanzarInforme: 'Generar informe',
      fechaInforme: 'Data informe',
      export: 'Exportar informe (.csv)',
      exportActual: 'Exportar informe actual (.csv)',
      ExportListaDis: 'Exportar betes (.txt)',
    },
    listaCombinacion: {
      numeroMiembros: 'Membres',
      autoconsumoEuro: 'Autoconsum €',
      excedenteEuro: 'Excedent €',
      ahorrosEuro: 'Estalvi €',
      autoconsumoPorcentaje: 'Autoconsum %',
      excedentePorcentaje: 'Excedent %',
      esLaOptima: 'Òptim',
    },
    tagPending: 'Pendiente',
    validate: 'Validar',
    page: 'Pàgina',
    notResult: 'No hi ha cap sol·licitud de canvi de tarifa',
    detalles: 'Veure',
    PendientaValidar: 'Pendent de validar',
    mensajeModalDelete: 'Està segur que vol eliminar el perfil: ',
    mensajeModalDuplicar: 'Està segur que vol duplicar la configuració ',
    mensajeModalDuplicarAdd: 'Afegir un nom diferent al actual',
  },
  PerfilListPage: {
    titleDetalle: 'Perfil de consum ',
    title: 'Perfils de consum',
    datosTarifa: 'Dades tarifa',
    datosSolicitud: 'Dades sol·licitud',
    searchPlaceholder: 'Buscar',
    add: 'Afegir un perfil',
    addMember: 'Afegir un perfil de consum',
    addPerfilConsumo: 'Afegir la corba de consum en format .csv',
    addPerfilGeneracion: 'Afegir la corba de generació en format .csv',
    showPerfilConsumoCsv: 'Corba de consum en format .csv',
    headers: {
      name: 'Nom',
      nif: 'Nif',
      notResult: 'No hi ha cap sol·licitud de canvi de tarifa',
      CUPS: 'Cups',
      report: 'Informe',
      comunidad: 'Comunitat',
      numberMember: 'Membres',
      errorComunidad: 'Error en obtenir el nom de la comunidat',
      fecha: `Data`,
    },
    addMatcher: {
      description: 'Afegir una configuració al Matcher',
      generacio: 'Generació solar',
      miembros: 'Llista de membres',
      generacioDesc: 'Puja la corba generació solar',
      comportamiento: 'Configuració comportamiento',
      betamax: 'βmax',
      betamin: 'βmin',
      betastep: 'βstep',
      maxMiembros: 'Maximitzar membres',
      minMiembros: 'Minimitzar membres',
      comunidad: 'Selecciona comunitat',
      name: 'Nom',
      apellidos: 'Cognoms',
      nif: 'Nif',
      cups: 'CUPS',
      seleccionado: 'Seleccionat',
      vip: 'Vip',
      preferent: 'Preferent',
      exportGeneracioDescExist: 'Desitja descarregar la corba de consum?',
    },
    actions: {
      ver: 'Veure',
      delete: 'Eliminar',
    },
    informe: {
      numeroDeMiembrosSelecionados: 'Quantitat de membres seleccionats',
      numeroDeMiembrosAceptados: 'Quantitat de membres acceptats',
      generacion: 'Generació',
      consumo: 'Consum',
      autoConsumo: 'Autoconsum',
      excedente: 'Excedent',
      autoConsumoEuro: 'Autoconsum en Euros',
      miembroExcluido: 'Membres exclosos',
      miembroAceptado: 'Membres acceptats',
      numeroMiembros: 'Membres',
      autoconsumoEuro: 'Autoconsum €',
      excedenteEuro: 'Excedent €',
      ahorrosEuro: 'Estalvi €',
      autoconsumoPorcentaje: 'Autoconsum %',
      excedentePorcentaje: 'Excedent %',
      esLaOptima: 'Òptim',
    },
    labels: { descripcion: 'Descripció' },

    tagPending: 'Pendiente',
    validate: 'Validar',
    page: 'Pàgina',
    notResult: 'No hi ha cap sol·licitud de canvi de tarifa',
    detalles: 'Veure',
    PendientaValidar: 'Pendent de validar',
    perfilesConsumo_fetching_error: 'Error en carregar la informació dels perfiles de consum.',
    AddPerfilesConsumo_fetching: 'Guardat Correctament',
    AddPerfilesConsumo_fetching_error: 'Error en crear el perfil de consum.',
    EditPerfilesConsumo_fetching_error: 'Error en carregar la informació del perfil de consum.',
    EliminarPerfilesConsumo_fetching_error: 'Error en elimina el perfil de consum.',
    EliminarPerfilesConsumo_fetching: 'Eliminat correctament',
    UpdatePerfilesConsumo_fetching: 'Perfil actualitzat amb èxit',
    UpdatePerfilesConsumo_fetching_error: 'Error al actualitzar el perfil de consumo.',
    ConsumoAnual: 'Consum anual',
    tituloCurva: 'Desitges actualitzar la corba de consum?',
  },
  ComunidadesDetallePage: {
    linkCopied: 'Enllaç copiat al portapapers del genèric',
    linkCopiedPromotor: 'Enllaç del promotor copiat al portapapeles',
    copyUrl: "Copiar l'URL del genèric",
    copyUrlComunidad: 'Copiar URL de la comunitat',
    linkCopiedComunidad: 'Enllaç copiat al portapapers',

    copyUrlPromotor: 'Copiar URL del promotor',
    imageData: {
      title: 'Imatge principal',
      Logo: 'Logo comunitat',
    },
    detailData: {
      title: 'Fitxa Detall',
      labels: {
        title: 'Titol',
        name: 'Nom',
        domicilio: 'Ubicació',
        poblacion: 'Població',
        provincia: 'Província',
        descripcion: 'Descripció',
        date: "Termini d'inscripció finalitzada",
        tipologia: 'Tipologia',
        promocion: 'Promoció',
        generacion: 'Generació instal·lable',
        potenciainstalable: 'Potència instal·lable',
        titularPoliticaPrivacidad: 'Titular de la política de privacitat',
        codigoPostal: 'Codi postal',
        limitacionNumeroInscripciones: "El nombre de límits d'inscripcions",
        co2: 'Estalvi CO2',
        arbres: 'Arbres plantats',
        members: 'Nombre màx. membres',
        estado: ' Marca com completat',
        radioAlcance: `Radi d'abast (en metres)`,
        footer1: 'footer1',
        footer2: 'footer2',
        consumoAnualPromedio: 'Consum Anual Mitjà',
      },
      tipologiaOptions: {
        industrial: 'Industrial',
        residencial: 'Residencial',
      },
      promocionOptions: {
        publica: 'Pública',
        privada: 'Privada',
        privadaYPrivada: 'Pública i privada',
      },
      curva: {
        subidaCorrectamente: 'Corba de generació pujada correctament',
        subidaIncorrectamente: 'Curva de generacion subida incorrectamente',
        subidaCorrectamenteConsumo: 'Corba de consum pujada correctament',
        subidaIncorrectamenteConsumo: 'Corba de consum pujada incorrectament',
        exportCurva: 'Descarregar corba generació',
        exportCurvaConsumo: 'Descarregar corba de consum',
        yaHayCsv: 'Ja existeix un csv',
      },
    },
    members: {
      title: 'Membres',
      labels: {
        name: 'Nombre',
        efficiency: 'Eficiència',
        status: 'Estat',
        admitted: 'Admès',
        verDetalle: 'Veure',
      },
    },
  },

  InscritosDetalle: {
    nombre: 'Nom',
    Dni: 'Dni',
    Email: 'Email',
    apellidos: 'Cognoms',
    telefon: 'Telèfon',
    Direccion: 'Direcció Completa',
    domicilio: 'Domicili',
    poblacion: 'Població',
    provincia: 'Província',
    razonSocial: 'Raó Social',
    codigoPostalFiscal: 'Codi Postal Fiscal',
    domicilioFiscal: 'Domicili Fiscal',
    nifEmpresa: 'NIF Empresa',
    codigoPostal: 'Codi Postal',
    precioCompensacion: 'Preu Compensació',
    tipoConsumo: 'Tipus de consum',
    tipoInscrito: "Tipus d'inscrit",
    tipoPropiedad: 'Tipus de propietat',
    tipoTarifa: 'Tipus de tarifa',
    tarifa1: '2.0TD',
    tarifa2: '3.0TD',
    cups: 'Cups',
    deleteInscrito: 'Eliminar inscrit',
    mensajeModalDelete: "Està segur que vol eliminar l'inscrit: ",
  },
  ComunidadesCustomOnboardingProcess: {
    updateSuccess: 'Dades actualitzades correctament.',
    errors: {
      errorGettingCustomOnboardingData: 'Error obtenin la informació personalitzada',
      updating: 'Error actualizant la informació personalitzada',
    },
    form: {
      title: 'Personalitza el onboarding de la comunitat',
      section1: 'Activar/Desactivar personalització',
      section2: 'Configuració de personalització de contingut',
      forzarRevision: "Forçar revisió/validació d'actualitzar tarifes",
      faqTilte: 'Afegir Faqs',
      horarioTitle: 'Afegir Horaris',
      horarios: 'Horaris',
      tipodeContacto: 'Tipus de contacte',
      tipodeContactoTitle: 'Afegir Tipus de contacte ',
      tipodePopUpTitle: 'Afegir configuració del popup',
      ofertaTitle: 'Ofertes',
      ofertaPageTitle: 'Condicions generals Ofertes',
      confirmacionMensajeTab: 'Confirmació missatge',
      otrosTab: 'Altres',
      PopUp: 'Modal passos onboarding',
      informe: "¿Voldries activar l'opció de rebre Informes Automàtics?",
      confirmacionMensaje: "Confirmació missatge de finalització d'onboarding",
      confirmacionMensajeExplicacion:
        "Escriu el títol i contingut del missatge de confirmació de finalització d'onboarding",
      labels: {
        tituloPopUpInicial: 'Títol del Pop-Up Inicial',
        descripcionPopUpInicial: 'Descripció del Pop-Up Inicial',
        paso1PopUpInicial: 'Pas 1 del Pop-Up Inicial',
        paso2PopUpInicial: 'Pas 2 del Pop-Up Inicial',
        paso3PopUpInicial: 'Pas 3 del Pop-Up Inicial',
        paso4PopUpInicial: 'Pas 4 del Pop-Up Inicial',
        tipodeContacto: 'Tipus de contacte',
        email: 'Email de contacte',
        telephone: 'Telèfon de contacte',
        privacyPolicy: 'Política de privadesa',
        descriptionTextStep2: 'Text descripció pas 2',
        successButtonText: "Text pel botó de la pàgina d'exit",
        primaryColor: 'Color principal',
        secondaryColor: 'Color secundari',
        AppCustom: 'Personalització de la App',
        WebCustom: 'Personalització de la web',
        forzarpaso2: "Saltar el pas 2 de l'onboarding.",
        comunicacionesComerciales: 'Mostrar check de sol·licitud de contacte comercial',
        tituloFinalizacionOnboarding: 'Títol',
        mensajeFinalizacionOnboarding: 'Contingut',
        subtituloOnboarding: 'Subtítol / contingut',
        peuDePagina: 'Peu de pàgina',
        forzarRevisionActualizarTarifa: 'Forçar revisió per actualitzar tarifa',
        informes: 'Informes Automàtics',
        informeInmediato: 'Enviar informe del primer intent',
      },
      placeholder: {
        email: 'Email de contacte',
        telephone: 'Telèfon de contacte',
        privacyPolicy: 'Política de privadesa',
        descriptionTextStep2: 'Text descripció pas 2',
        successButtonText: "Text pel botó de la pàgina d'exit",
        primaryColor: 'Color principal',
        secondaryColor: 'Color secundari',
        tituloFinalizacionOnboarding: '¡Sol·licitud enviada!',
        mensajeFinalizacionOnboarding: 'Moltes gràcies per haver-nos facilitat les teves dades energètiques ...',
      },
      logo: {
        title: 'Logo Navbar',
        text: 'afegeix logotip per al Navbar',
        note: "(Ha de ser d'AxBpx*)",
        button: 'afegeix logo',
      },
      image01: {
        title: 'Imagen paso 1',
        text: 'afegeix imatge del pas 1',
        note: "(Ha de ser d'950x527px *)",
        button: 'afegeix imatge',
      },
      image02: {
        title: 'Imagen paso 2',
        text: 'afegeix imatge del pas 2',
        note: "(Ha de ser d'950x527px *)",
        button: 'afegeix imatge',
      },
      image03: {
        title: 'Imagen paso 3',
        text: 'afegeix imatge del pas 3',
        note: "(Ha de ser d'950x527px *)",
        button: 'afegeix imatge',
      },
      image04: {
        title: 'Imagen paso 4',
        text: 'afegeix imatge del pas 4',
        note: "(Ha de ser d'950x527px *)",
        button: 'afegeix imatge',
      },
      image05: {
        title: 'Imagen final',
        text: 'afegeix imatge final',
        note: "(Ha de ser d'950x527px *)",
        button: 'afegeix imatge',
      },
    },
  },
  TooltipOferta: {
    title: "Puja un arxiu CSV de l'any",
    subtitle:
      "L'arxiu CSV ha de contenir l'any complet amb les dates en format: YYY/MM/DD i les hores: 1-24. Tingues en compte que totes les columnes han d'estar separades amb ‘;’. Per exemple: 2023/1/1;1;164 ",
    BetaMaxTitulo:
      "Aquest camp s'utilitza per a posar límit de cada membre de la comunitat. El valor d'aquest camp influeix molt a l'hora de calcular els resultats. Aquí tens alguns consells:",
    BetaMaxLista1: 'Estableix el valor en un rang recomanat entre 0.1 i 0.4.',

    BetaMaxLista2: 'La suma de BetaMax de tots membres ha de ser més o igual a 1.0',

    BetaMaxLista3: "Assegura't que reflecteixi la quantitat de membres i les seves grandàries.",
    BetaMinTitulo:
      "Aquest camp s'utilitza per a assegurar que tots els clients obtenen la seva part d'energia solar en les hores de falta de generació. També serveix com un limito per a filtrar les combinacions amb sobre de membres:",
    BetaMinLista1: 'Estableix el valor en un rang recomanat entre 0.0 i 0.1.',

    BetaMinLista2: 'La suma de *BetaMin de tots membres ha de ser menys o igual a 1.0',

    BetaMinLista3: "Assegura't que sigui prou baix per a evitar excedents d'energia.",

    AddBetaMaxTitulo:
      "Aquest camp s'utilitza per a clients grans que tenen una major participació en el consum d'energia de la comunitat,que tinguin més BetaMax que la configuració Matcher. Aquí tens alguns consells:",
    AddBetaMaxLista1: 'Estableix el valor en un rang recomanat entre 0.1 i 0.5.',

    AddBetaMaxLista2: "Assegura't que reflecteixi una proporció eficient d'intercanvi d'energia.",

    AddBetaMinTitulo:
      "Aquest camp s'utilitza per a clients petits que tenen una menor participació en el consum d'energia de la comunitat, que tingui menys de la *BetaMin de la configuració *Matcher. Segueix aquests consells:",
    AddBetaMinLista1: 'Estableix el valor en un rang recomanat entre 0.0 i 0.1.',

    AddBetaMinLista2: "Assegura't que sigui prou baix per a evitar excedents d'energia.",
    TerminoEnergiaTitulo:
      'Este conjunto de campos contiene valores cruciales para los cálculos de energía. Aquí tienes algunos consejos:',
    TerminoEnergiaLista1: 'Mantén los valores actualizados y reflejando los precios actuales en euros.',
    TerminoEnergiaLista2:
      'Por ejemplo, si el término de energía P1 de un cliente es de 15 céntimos, introdúcelo como 0.15 en el sistema.',

    ExcedenteTitulo: "Aquest camp s'utilitza per a calcular els excedents. Segueix aquestes recomanacions:",
    ExcedenteLista1: 'Mantingues el valor actualitzat i en euros.',
    ExcedenteLista2:
      'Per exemple, si el preu de compensació del client és de 7 cèntims, introdueix-lo com 0.07 en el sistema.',
  },
  RolDetailPage: {
    RolData: {
      title: ' Administració de Rols i Permisos',
      Actualizar: 'Modificar perfil',
      perfiles: 'Rols',
      MensajeModal: 'Està segur que vol eliminar',
      crear: 'Crear nou perfil',
      error: 'Error',
      ver: 'Veure',
      labels: {
        name: 'Nom',
      },
    },
  },
  RolPermisionDetailPage: {
    RolData: {
      titleRol: 'Administració de Rols',
      titlePermisos: 'Administració de Permisos',

      subtitle: 'Accés',
      update: 'Actualitzar nom',
      mensajeModalDelete: 'Està segur que vol eliminar ',
      labels: {
        name: 'Nom',
        selected: 'Seleccionat',
      },
    },
  },
  UsuarioRolPage: {
    title: 'Rols assignats',
    noData: "L'usuari no té CUPS vinculats.",
    headers: {
      rol: 'Rol',
      estado: 'Estat',
    },
    viewInstallation: 'Veure instal·lació',
    noRole: 'No té cap permís assignat',
  },
  UsuarioCupsAssociarPage: {
    title: 'Cups autoritzats',
    subtitle: 'Autoritzar CUPS',
    noData: "L'usuari no té CUPS vinculats.",
    headers: {
      cups: 'CUPS',
      estado: 'Estat',
      name: 'Nom',
      domicilio: 'Domicili',
    },
    viewInstallation: 'Veure instal·lació',
    noRole: 'No té cap cups assignat',
    quitar: 'treure',
  },
  SolicitudCupsTerceroPage: {
    title: "Sol·licitud d'Autorització per a CUPS de Tercers",
    titleEnviada: 'Sol·licituds enviades',
    titleRecivida: 'Sol·licituds rebudes',
    error: "S'ha produït un error",
  },
};

export default translations;
