/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { I18n } from 'core';
import FullPageSyncLoader from 'components/FullPageSyncLoader';
// import ConfiguracionMatcher from 'types/models/ConfiguracionMatcher';
import styles from './table-matcher.module.scss';

interface TableMatcherProps {
  headerMatcherHeader: string[];
  // Esto sera los miembros obtenidos que tenga es a configuracion
  miembrosMatcher: Array<any>;
  renderRow: (data: any, index: any) => JSX.Element;
}
const TableMatcherWithFilter = ({
  headerMatcherHeader,
  miembrosMatcher,
  renderRow,
}: TableMatcherProps): JSX.Element => {
  const [miebros, setMiembrosMatcher] = useState(miembrosMatcher); // Your initial miembrosMatcher array
  const [sortColumn, setSortColumn] = useState(''); // Columna actualmente ordenada
  const [sortDirection, setSortDirection] = useState('asc'); // Dirección de ordenamiento ('asc' o 'desc')

  // seleccionado, vip, preferente;
  const handleClick = (columnIndex: number) => {
    const columnName = headerMatcherHeader[columnIndex].toLowerCase();
    let direction = 'asc';

    // Si ya se está ordenando por la misma columna, cambia la dirección
    if (columnName === sortColumn) {
      direction = sortDirection === 'asc' ? 'desc' : 'asc';
    }

    setSortColumn(columnName);
    setSortDirection(direction);
    // Realiza el ordenamiento según la columna y dirección
    const sortedMiembrosMatcher = [...miembrosMatcher];
    switch (columnName) {
      case I18n.t('MatcherListPage.addMatcher.name'):
        sortedMiembrosMatcher.sort((a, b) => a.nombre.localeCompare(b.nombre) * (direction === 'asc' ? 1 : -1));
        break;
      case I18n.t('MatcherListPage.addMatcher.apellidos').toLowerCase():
        sortedMiembrosMatcher.sort((a, b) => a.apellidos.localeCompare(b.apellidos) * (direction === 'asc' ? 1 : -1));
        break;
      case I18n.t('MatcherListPage.addMatcher.consumo').toLowerCase():
        sortedMiembrosMatcher.sort((a, b) => (a.consumoAnual - b.consumoAnual) * (direction === 'asc' ? 1 : -1));
        break;
      case I18n.t('MatcherListPage.addMatcher.seleccionado').toLowerCase():
        sortedMiembrosMatcher.sort(
          (a, b) => a.seleccionado.localeCompare(b.seleccionado) * (direction === 'asc' ? 1 : -1),
        );
        break;
      case I18n.t('MatcherListPage.addMatcher.nif').toLowerCase():
        sortedMiembrosMatcher.sort((a, b) => a.nif.localeCompare(b.nif) * (direction === 'asc' ? 1 : -1));
        break;
      case I18n.t('MatcherListPage.addMatcher.vip').toLowerCase():
        sortedMiembrosMatcher.sort((a, b) => a.miembroVip.localeCompare(b.miembroVip) * (direction === 'asc' ? 1 : -1));
        break;
      case I18n.t('MatcherListPage.addMatcher.preferent').toLowerCase():
        sortedMiembrosMatcher.sort(
          (a, b) => a.miembroPreferente.localeCompare(b.miembroPreferente) * (direction === 'asc' ? 1 : -1),
        );
        break;
      default:
        break;
    }

    setMiembrosMatcher(sortedMiembrosMatcher);
  };
  useEffect(() => {
    // Update the miebros state when miembrosMatcher changes
    setMiembrosMatcher(miembrosMatcher);
  }, [miembrosMatcher]);

  const getSortIcon = (columnName: string) => {
    if (columnName === sortColumn.charAt(0).toUpperCase() + sortColumn.slice(1)) {
      return sortDirection === 'asc' ? <span>&#9650;</span> : <span>&#9660;</span>;
    }

    return null;
  };

  return (
    <table>
      <thead>
        <tr>
          {headerMatcherHeader.map((headerName: string, index: number) => (
            <th
              style={{ textAlign: [0, 5, 6].includes(index) ? 'center' : 'left' }} // Cambiar 'left' si las otras celdas deben tener alineación diferente
              key={index}
              onClick={() => handleClick(index)}
            >
              {headerName}
              {getSortIcon(headerName)}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {miebros ? (
          (() => {
            if (miebros && miebros.length > 0) {
              return miebros.map((rowData, index) => renderRow(rowData, index));
            }
            return (
              <tr className={styles.Sinboder}>
                <td className={styles.nodata}>{I18n.t('noData')}</td>
              </tr>
            );
          })()
        ) : (
          <FullPageSyncLoader icon />
        )}
      </tbody>
    </table>
  );
};

export default TableMatcherWithFilter;
